import { useState } from 'react'
import './Auth.scss'
import image from '../../assets/images/4.jpeg'
import FormLoader from '../../components/loader/FormLoader'
import { Link, useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../utils/Context'
import { postNo } from '../../utils/Api'
function ForgotPassword() {
  const { notify } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  function handleSubmit(data: any) {
    setLoading(true)
    postNo('auth/password-reset-link', data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          notify(
            'success',
            'Password reset link has been sent successfully, check your email address and finish up the reset process. '
          )
          setTimeout(() => {
            navigate('/users/login')
          }, 2000)
        } else {
          notify('error', res.message)
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }
  return (
    <div className='auth flex'>
      <div className='left'>
        <img src={image} alt='delivery man image' />
      </div>
      <div className='right flex items-center'>
        <div className='wrap'>
          <div className='top'>
            <h1>Forgot Password</h1>
            <p>
              Enter your email address and we'll send you a link to reset your
              password.
            </p>
          </div>
          <form
            onSubmit={(e: any) => {
              e.preventDefault()
              const email = e.target.email.value
              if (!email) {
                notify('warn', 'Email is required')
                return
              }
              // Handle form submission
              handleSubmit({ email: email.toLowerCase() })
            }}
          >
            <div className='card'>
              <p>Email</p>
              <input type='email' name='email' />
            </div>
            <div className='card btn'>
              {loading ? (
                <FormLoader position='center' />
              ) : (
                <button type='submit'>Reset password</button>
              )}
            </div>
            <div className='card forgot '>
              <Link to='/users/login'>
                <p> Sign In</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
