const WhyData = [
  {
    icon: 'f7:shippingbox-fill',
    tag: 'Shipping management',
    content:
      'We’ve got shipping covered! From order processing to tracking, we organize and optimize every step of the delivery process. With real-time updates and smart route planning, we reduce delays and keep things running smoothly. You can count on us to handle your shipments with care.',
  },
  {
    icon: 'material-symbols:avg-time-sharp',
    tag: 'On-time delivery',
    content:
      'We know how important it is to get your shipments on time. That’s why we’re all about making sure your goods arrive exactly when you need them. With our efficient logistics software system and expert route planning, we minimize delays and keep things reliable. ',
  },
  {
    icon: 'tdesign:secured-filled',
    tag: 'Secured and Organised',
    content:
      'Your goods are in safe hands with us. We make sure everything is handled and transported securely, from start to finish. Our organized logistics system keeps things clear and easy, giving you full visibility and control over your goods. Rest easy knowing your deliveries are in good hands.',
  },
  {
    icon: 'solar:delivery-bold',
    tag: 'Guaranteed delivery',
    content:
      'When we say we’ll deliver, we mean it! We do it! Our guaranteed delivery service ensures your shipments arrive on time, every time. We take full responsibility for meeting your deadlines, so you don’t have to worry. With our reliable network and careful planning, you’ll always stay informed and at peace knowing your goods are in safe hands.',
  },
  {
    icon: 'ix:customer-filled',
    tag: 'Excellent Customer Experience',
    content:
      'We’re with you every step of the way—from placing your order to the final delivery. Our team is always ready to help, making sure communication is smooth and support is always there when you need it. With us, you’ll experience the best service tailored to what you need and how you want it.',
  },
  {
    icon: 'jam:triangle-danger-f',
    tag: 'Zero Risks',
    content:
      'No worries, no hassle. We’ve got a zero-risk approach for every shipment. Our secure, reliable procedures ensure your goods are delivered safely and without any problems. We take care of every little detail, from pickup to drop-off, to make sure everything goes smoothly.',
  },
]
export default WhyData
