import { useState } from 'react'
import FormLoader from '../../../../components/loader/FormLoader'
import Modal from '../../../../components/modal/Modal'
import { useParams } from 'react-router-dom'
import { put } from '../../../../utils/Api'
import { useGlobalContext } from '../../../../utils/Context'

function CancelBooking({ fetchBooking, setShowCancel }: any) {
  const { id } = useParams()
  const { notify } = useGlobalContext()
  const [cancelLoading, setCancelLoading] = useState(false)
  const [reason, setReason] = useState('')

  async function cancelBooking() {
    if (reason.length < 5) {
      notify('warn', 'A reason is required')
      return
    }
    const data = {
      bookingId: id,
      reason: reason,
    }
    setCancelLoading(true)
    put('user/cancel-booking', data)
      .then((result: any) => {
        if (result.status === 200) {
          notify('success', 'Booking Cancelled')
          setShowCancel(false)
          fetchBooking()
        } else {
          notify('error', result.message)
        }
        setCancelLoading(false)
      })
      .catch(() => {
        setCancelLoading(false)
      })
  }
  return (
    <Modal position='center'>
      <div className='payment-modal'>
        <h1>Cancel Booking</h1>
        <p>Note: You are about to Cancel a booking</p>
        <input
          type='text'
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        {cancelLoading ? (
          <FormLoader position='center' />
        ) : (
          <div className='buttons flex justify-center'>
            <button onClick={() => setShowCancel(false)}>Cancel</button>
            <button className='proceed' onClick={cancelBooking}>
              Proceed
            </button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default CancelBooking
