// components/ProtectedRoute.tsx
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute: React.FC = () => {
  // Check for the presence of an auth token
  const authToken = sessionStorage.getItem('token')
  // If no token, redirect to login
  return authToken ? <Outlet /> : <Navigate to='/' replace />
}

export default ProtectedRoute
