import { useState } from 'react'
import DHLOffices from '../../../../assets/data/DHLOffices'

function DropOff() {
  const [tab, setTab] = useState(0)
  return (
    <div className='drop-off flex justify-center'>
      <div className='wrapper'>
        <div className='location-card'>
          <h2>Lagos</h2>
          <div className='tab-row'>
            <div className='row-top' onClick={() => setTab(1)}>
              <h4>IKOSI/MAGODO</h4>
            </div>
            {tab === 1 && (
              <div className='row-body'>
                <p>
                  14A Olatunji Close, Ikosi GRA IV, Off CMD Road, Magodo Lagos
                </p>
              </div>
            )}
          </div>
          <div className='tab-row'>
            <div className='row-top' onClick={() => setTab(2)}>
              <h4>LAGOS ISLAND</h4>
            </div>
            {tab === 2 && (
              <div className='row-body'>
                <p>
                  (2nd Floor, Foresight House), 163/165 Broad Street, Marina,
                  Lagos Island, Lagos.
                </p>
              </div>
            )}
          </div>
          <div className='tab-row'>
            <div className='row-top' onClick={() => setTab(21)}>
              <h4>Lekki</h4>
            </div>
            {tab === 21 && (
              <div className='row-body'>
                <p>
                  Riella Mall, Shop No.12 (first Floor), Block 113, Plot 27,
                  Oladimeji Alo Street, Off Freedom Way, Lekki, Lagos.
                </p>
              </div>
            )}
          </div>
          <div className='tab-row'>
            <div className='row-top' onClick={() => setTab(3)}>
              <h4>NAHCO</h4>
            </div>
            {tab === 3 && (
              <div className='row-body'>
                <p>
                  Payment Point 3, NAHCO Car Park3, Murtala Muhammed cargo
                  airpor, Lagos.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className='location-card'>
          <h2>Other Locations</h2>
          {DHLOffices.map((item, i) => (
            <div className='tab-row'>
              <div className='row-top' onClick={() => setTab(i + 4)}>
                <h4>{item.name}</h4>
              </div>
              {tab === i + 4 && (
                <div className='row-body'>
                  <p>{item.address}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DropOff
