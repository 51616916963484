import { useState } from 'react'
import './Auth.scss'
import image from '../../assets/images/4.jpeg'
import FormLoader from '../../components/loader/FormLoader'
import { Link, useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../utils/Context'
import { postNo, putNo } from '../../utils/Api'

function CreateAccount() {
  const { notify } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [tab, setTab] = useState(1)
  const [auth, setAuth] = useState<any>({})
  const navigate = useNavigate()

  function handleSubmit(data: any) {
    setAuth(data)
    setLoading(true)
    postNo('user/create', data)
      .then((res) => {
        if (res.status === 200) {
          notify('success', res.message)
          setTab(2)
          setLoading(false)
        } else {
          notify('error', res.message)
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  function handleVerify() {
    const submit = {
      code: token,
      email: auth.email.toLowerCase(),
    }
    if (!token) {
      notify('warn', 'token is required')
      return
    }
    setLoading(true)
    putNo('user/verify-email', submit)
      .then((res) => {
        if (res.status === 200) {
          notify('success', res.message)
          navigate('/users/login')
        } else {
          notify('error', res.message)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  return (
    <div className='auth flex'>
      <div className='left'>
        <img src={image} alt='delivery man image' />
      </div>
      <div className='right flex items-center'>
        {tab === 1 ? (
          <div className='wrap'>
            <div className='top'>
              <h1>Create Account</h1>
              <p>To continue, create an account</p>
            </div>
            <form
              onSubmit={(e: any) => {
                e.preventDefault()
                const firstname = e.target.firstname.value
                const email = e.target.email.value
                const lastname = e.target.lastname.value
                const phonenumber = e.target.phonenumber.value
                const password = e.target.password.value
                const username = e.target.username.value
                // const friend = e.target.friend.value
                if (
                  !firstname ||
                  !email ||
                  !lastname ||
                  !phonenumber ||
                  !password ||
                  !username
                ) {
                  notify('warn', 'Please fill in all compulsory fields')
                  return
                }
                const alphanumericRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/
                if (!alphanumericRegex.test(password)) {
                  notify(
                    'warn',
                    'Password must include both uppercase and lowercase letters, and numbers'
                  )
                  return
                }
                if (!alphanumericRegex.test(username)) {
                  notify(
                    'warn',
                    'Username must include both uppercase and lowercase letters, and numbers'
                  )
                  return
                }
                // Handle form submission
                handleSubmit({
                  firstname,
                  email: email.toLowerCase(),
                  lastname,
                  phonenumber,
                  password,
                  username,
                  // friend,
                })
              }}
            >
              <div className='flex gap-16'>
                <div className='card'>
                  <p>
                    First Name <span className='color-red'>*</span>
                  </p>
                  <input type='text' name='firstname' />
                </div>
                <div className='card'>
                  <p>
                    Last Name <span className='color-red'>*</span>
                  </p>
                  <input type='text' name='lastname' />
                </div>
              </div>
              <div className='card'>
                <p>
                  Email <span className='color-red'>*</span>
                </p>
                <input type='email' name='email' />
              </div>
              <div className='flex gap-16'>
                <div className='card'>
                  <p>
                    Phone Number <span className='color-red'>*</span>
                  </p>
                  <input type='tel' name='phonenumber' />
                </div>
                <div className='card'>
                  <p>
                    Username <span className='color-red'>*</span>
                  </p>
                  <input type='text' name='username' />
                </div>
              </div>
              <div className='card'>
                <p>
                  Password <span className='color-red'>*</span>
                </p>
                <input type='password' name='password' />
              </div>

              {/* <div className='card'>
                <p>Referral code</p>
                <input type='text' name='friend' />
              </div> */}
              <div className='card btn'>
                {loading ? (
                  <FormLoader position='center' />
                ) : (
                  <button type='submit'>Sign Up</button>
                )}
              </div>
              <div className='card forgot '>
                <Link to='/forgot-password'>
                  <p>Forgot Password?</p>
                </Link>
              </div>
            </form>
          </div>
        ) : (
          <div className='wrap'>
            <div className='top'>
              <h1>Verify OTP</h1>
            </div>
            <div className='card'>
              <p>OTP</p>
              <input
                type='tel'
                name='token'
                onChange={(e) => setToken(e.target.value)}
              />
            </div>
            <div className='card btn'>
              {loading ? (
                <FormLoader position='center' />
              ) : (
                <button onClick={handleVerify}>Verify Email</button>
              )}
            </div>
            <div className='card forgot '>
              <Link to='/'>
                <h4>Forgot Password?</h4>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CreateAccount
