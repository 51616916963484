const FaqsData = [
  {
    question: 'What destinations can I ship to?',
    answer:
      'QC Express ships to many locations, both locally and internationally. To check if we deliver to a specific destination, visit our website or contact our customer support team.',
  },
  {
    question: 'Can I send anything on QC Express?',
    answer:
      'While we handle most types of shipments, there are restrictions on certain items for safety and legal reasons. Prohibited and restricted items may include hazardous materials, perishable goods, firearms, and certain electronics. Please refer to our shipping guidelines or reach out to our support team for more details on what can and cannot be shipped.',
  },
  {
    question:
      'If I do not have my tracking number, is it still possible to track my shipment?',
    answer:
      'Yes, in some cases. If you do not have your tracking number, you can try tracking your shipment using your order reference number, email address, or phone number associated with the shipment. Contact our support team for assistance, and we will help you locate your package’s status.',
  },
  {
    question: 'Can I estimate the size and weight of a parcel?',
    answer:
      'Yes! You can estimate the size and weight of your parcel using our online shipping calculator. Simply enter the dimensions and weight to get an estimate of the shipping cost. For accuracy, we recommend using a scale and measuring tape before finalizing your shipment.',
  },
  {
    question:
      'The courier could not take my shipment because it is too big/heavy for their vehicle. What should I do?',
    answer:
      'If your shipment exceeds the size or weight limit of the assigned vehicle, we can arrange for an alternative solution. Please contact our support team to discuss options such as using a larger vehicle, splitting the shipment, or selecting a different service that accommodates your package’s dimensions.',
  },
  {
    question: 'What if my shipment is lost or damaged?',
    answer:
      'If your shipment is lost or damaged, contact us immediately. If your package was insured, we will guide you through the claims process.',
  },
  {
    question:
      "My shipment is showing 'Custom status updated' from the destination. What does this mean?",
    answer:
      'This status means that your shipment has reached the customs department at the destination country and is being processed for clearance. If additional documentation or duties are required, you may be contacted. You can check for further updates or reach out to our support team for assistance.',
  },
  {
    question:
      "What should I do if the courier can't take a parcel and I was already charged for the shipment?",
    answer:
      'If our courier is unable to pick up your parcel and you were already charged, please contact our customer support team immediately. We will investigate the issue and either reschedule the pickup or issue a refund based on the situation.',
  },
]

export default FaqsData
