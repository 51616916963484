const ReviewsData = [
  {
    id: 1,
    name: 'Faderera',
    company: 'CEO, Faderera Fabrics',
    review: `“I heard about QC Express and sent a customer's parcel through them. The experience was delightful as the package was delivered even before the promised delivery date. I have found a trusted partner to help us manage package delivery to our numerous customers.”`,
    image:
      'https://res.cloudinary.com/dhsg45mob/image/upload/v1740957295/qc-express/reviews/teamtes_hjbf9j.png',
  },
  {
    id: 2,
    name: 'Gbolahan Salami',
    company: 'CEO, Digicomme',
    review: `“QC Express is an amazing and reliable platform. Thank you for the seamless pickup and delivery, looking forward more transactions with the platform.”`,
    image:
      'https://res.cloudinary.com/dhsg45mob/image/upload/v1740957302/qc-express/reviews/gbolahan_uy3prq.jpg',
  },
]

export default ReviewsData
