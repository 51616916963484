import { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../../../utils/Context'
import PageLoader from '../../../../components/loader/PageLoader'
import { get } from '../../../../utils/Api'
import FormLoader from '../../../../components/loader/FormLoader'

function Home() {
  const { profile, profileLoading, notify, fetchUserProfile } =
    useGlobalContext()
  const navigate = useNavigate()

  //<-------------- Bookings --------------->
  const [bookings, setBookings] = useState<any>({ data: [] })
  const [bookingsLoading, setBookingsLoading] = useState(true)

  function fetchBookings() {
    setBookingsLoading(true)
    get('booking/list?page=1&limit=10')
      .then((result: any) => {
        if (result.status === 200) {
          setBookings(result)
        } else {
          notify('error', result.message)
        }
        setBookingsLoading(false)
      })
      .catch(() => {
        setBookingsLoading(false)
      })
  }

  useEffect(() => {
    fetchUserProfile()
    fetchBookings()
  }, [])

  //<-------------- Transactions --------------->
  const [transactions, setTransactions] = useState<any>({ transactions: [] })
  const [transactionsLoading, setTransactionsLoading] = useState(true)

  function fetchTransactions() {
    setTransactionsLoading(true)
    get('user/transactions?page=1&limit=10')
      .then((result: any) => {
        if (result.status === 200) {
          setTransactions(result)
        } else {
          notify('error', result.message)
        }
        setTransactionsLoading(false)
      })
      .catch(() => {
        setTransactionsLoading(false)
      })
  }

  useEffect(() => {
    fetchTransactions()
  }, [])

  // Pay Stack payment
  return profileLoading ? (
    <div className='home'>
      <PageLoader position='center' />
    </div>
  ) : (
    <div className='home'>
      <div className='home-head flex justify-between flex-wrap'>
        <div className='left flex justify-between flex-wrap'>
          <div className='card'>
            <p>Wallet Balance</p>
            <h2>₦{profile.balance.toLocaleString()}</h2>
          </div>
          <div className='card'>
            <p>Booking Count</p>
            <h2>{profile.totalBookings.toLocaleString()}</h2>
          </div>
          <div className='card'>
            <p>Total Booking Value</p>
            <h2>₦{profile.totalCharge.toLocaleString()}</h2>
          </div>
          <div className='card'>
            <p>Total Transactions</p>
            <h2>
              {parseFloat(
                profile.totalTransactions.toFixed(2)
              ).toLocaleString()}
            </h2>
          </div>
        </div>
        <div className='right'>
          <div
            className='card flex items center'
            onClick={() => navigate('/user/export')}
          >
            <div className='icon'>
              <Icon icon={'arcticons:emoji-airplane-departure'} />
            </div>
            <div className='texts'>
              <h2>Export Delivery</h2>
              <p>Book shipment pickup for international deliveries</p>
            </div>
          </div>
          <div
            className='card flex items center'
            onClick={() => navigate('/user/import')}
          >
            <div className='icon'>
              <Icon icon={'arcticons:emoji-airplane-arriving'} />
            </div>
            <div className='texts'>
              <h2>Import Delivery</h2>
              <p>
                Book shipment pickup from international locations for deliveries
                in Nigeria
              </p>
            </div>
          </div>
          <div
            className='card flex items center'
            onClick={() => navigate('/user/domestic')}
          >
            <div className='icon'>
              <Icon icon='material-symbols-light:delivery-truck-speed-outline-rounded' />
            </div>
            <div className='texts'>
              <h2>Domestic Delivery</h2>
              <p>Book shipment pickup for deliveries within Nigeria</p>
            </div>
          </div>
        </div>
      </div>
      <div className='aza flex justify-between items-center flex-wrap'>
        <div className='card'>
          <h3>Account Name</h3>
          <p>
            {profile.firstname} {profile.lastname}
          </p>
        </div>
        <div className='card'>
          <h3>Account Number</h3>
          <p>{profile.account_number}</p>
        </div>
        <div className='card'>
          <h3>Bank Name</h3>
          <p>{profile.bank}</p>
        </div>
        <div className='card'>
          <h3>Referral Code</h3>
          <p>{profile.referral}</p>
        </div>
      </div>
      <div className='home_tables'>
        <div className='left'>
          <div className='left_top'>
            <h2>Recent Bookings</h2>
          </div>
          {bookingsLoading ? (
            <FormLoader position='center' />
          ) : (
            <>
              {bookings.data.length > 0 ? (
                <>
                  <div className='mobile-table'>
                    {bookings.data.map((item: any, i: number) => (
                      <div
                        className='card'
                        onClick={() => navigate(`/user/booking/${item.id}`)}
                      >
                        <div className='flex justify-between row'>
                          <div className='count flex items-center justify-center'>
                            {i + 1}
                          </div>
                          <p> {new Date(item.createdAt).toLocaleString()}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Amount</h4>
                          <p>₦{item.charge.toLocaleString()}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Shipment type</h4>
                          <p>{item.shipment_type}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Delivery type</h4>
                          <p>{item.deliveryType}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Status</h4>
                          <span className={`chip ${item.booking_status.code}`}>
                            {item.booking_status.description}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <table className='desktop-table'>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Date/Time</th>
                        <th>Amount</th>
                        <th>Shipment Type</th>
                        <th>Delivery Type</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookings.data.map((item: any, i: number) => (
                        <tr key={item.id}>
                          <td>{i + 1}</td>
                          <td className='count'>
                            {new Date(item.createdAt).toLocaleString()}
                          </td>
                          <td className='count'>
                            NGN {item.charge.toLocaleString()}
                          </td>
                          <td className='count'>{item.shipment_type}</td>
                          <td className='count'>{item.deliveryType}</td>
                          <td>
                            <span
                              className={`chip ${item.booking_status.code}`}
                            >
                              {item.booking_status.description}
                            </span>
                          </td>
                          <td>
                            <span
                              onClick={() =>
                                navigate(`/user/booking/${item.id}`)
                              }
                              className='view'
                            >
                              view
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <div className='empty flex justify-center'>
                  <div className='empty-cover'>
                    <Icon icon='majesticons:book-line' />
                    <h1>No Recent Bookings To Show</h1>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className='home_tables'>
        <div className='left'>
          <div className='left_top'>
            <h2>Recent Transactions</h2>
          </div>
          {transactionsLoading ? (
            <FormLoader position='center' />
          ) : (
            <>
              {transactions.transactions.length > 0 ? (
                <>
                  <div className='mobile-table'>
                    {transactions.transactions.map((item: any, i: number) => (
                      <div className='card'>
                        <div className='flex justify-between row'>
                          <div className='count flex items-center justify-center'>
                            {i + 1}
                          </div>
                          <p> {new Date(item.created_at).toLocaleString()}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Amount</h4>
                          <p>₦{item.amount.toLocaleString()}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Type</h4>
                          <p className={item.type}>{item.type}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Desc</h4>
                          <p>{item.usage}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Reference</h4>
                          <p>
                            {item.reference.length > 17
                              ? `${item.reference.slice(0, 17)}...`
                              : item.reference}
                          </p>
                        </div>

                        <div className='row flex justify-between'>
                          <h4>Status</h4>
                          <p className={item.status}>{item.status}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <table className='desktop-table'>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Date/Time</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Use</th>
                        <th>Reference</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.transactions.map((item: any, i: number) => (
                        <tr key={item._id}>
                          <td>{i + 1}</td>
                          <td className='count'>
                            {new Date(item.created_at).toLocaleString()}
                          </td>
                          <td className='count'>
                            NGN {item.amount.toLocaleString()}
                          </td>
                          <td className={item.type}>{item.type}</td>
                          <td>{item.usage}</td>
                          <td>{item.reference}</td>
                          <td className={item.status}>{item.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <div className='empty flex justify-center'>
                  <div className='empty-cover'>
                    <Icon icon='la:clipboard-list' />
                    <h1>No Recent Transaction To Show</h1>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Home
