import Logo from '../../../assets/images/logo.png'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../../utils/Context'

function Sidebar({ page }: any) {
  const { toggleSidebar } = useGlobalContext()
  const navigate = useNavigate()
  return (
    <div className={`sidebar ${toggleSidebar ? 'active' : ''}`}>
      <div className='sidebar-cover'>
        <div className='logo'>
          <img src={Logo} alt='qc express logo' />
        </div>
        <ul>
          <li
            className={`flex ${page === 'home' ? 'active' : ''}`}
            onClick={() => navigate('/aggregator/home')}
          >
            <div>
              <Icon icon='lucide:home' />
            </div>
            <p>Dashboard</p>
          </li>
          <li
            onClick={() => navigate('/aggregator/bookings')}
            className={`flex ${page.includes('booking') ? 'active' : ''}`}
          >
            <div>
              <Icon icon='icon-park-outline:tickets-two' />
            </div>
            <p>Bookings</p>
          </li>
          <li
            onClick={() => navigate('/aggregator/drafts')}
            className={`flex ${page.includes('draft') ? 'active' : ''}`}
          >
            <div>
              <Icon icon='ri:draft-fill' />
            </div>
            <p>Drafts</p>
          </li>
          <li
            onClick={() => navigate('/aggregator/transactions')}
            className={`flex ${page === 'transactions' ? 'active' : ''}`}
          >
            <div>
              <Icon icon='bitcoin-icons:transactions-outline' />
            </div>
            <p>Transactions</p>
          </li>
          <li
            onClick={() => navigate('/aggregator/drop-off')}
            className={`flex ${page === 'drop-off' ? 'active' : ''}`}
          >
            <div>
              <Icon icon='pepicons-pop:map-circle' />
            </div>
            <p>Drop-off</p>
          </li>
          <li
            onClick={() => navigate('/aggregator/trackings')}
            className={`flex ${page === 'trackings' ? 'active' : ''}`}
          >
            <div>
              <Icon icon='gis:location-poi' />
            </div>
            <p>Track Package</p>
          </li>
          <li
            onClick={() => navigate('/aggregator/settings')}
            className={`flex ${page.includes('setting') ? 'active' : ''}`}
          >
            <div>
              <Icon icon='lets-icons:setting-fill' />
            </div>
            <p>Settings</p>
          </li>
          <li
            onClick={() => {
              sessionStorage.clear()
              window.location.replace('/')
            }}
            className='flex'
          >
            <div>
              <Icon icon='line-md:log-out' />
            </div>
            <p>Log Out</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
