import { useState } from 'react'
import './Auth.scss'
import image from '../../assets/images/4.jpeg'
import FormLoader from '../../components/loader/FormLoader'
import { Link, useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../utils/Context'
import { putNo } from '../../utils/Api'
function ResetPassword() {
  const { notify } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')

  function handleSubmit(data: any) {
    setLoading(true)
    putNo(`auth/reset-password?token=${token}`, data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          notify('success', res.message)
          setTimeout(() => {
            navigate('/users/login')
          }, 2000)
        } else {
          notify('error', res.message)
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }
  return (
    <div className='auth flex'>
      <div className='left'>
        <img src={image} alt='delivery man image' />
      </div>
      <div className='right flex items-center'>
        <div className='wrap'>
          <div className='top'>
            <h1>Password Change</h1>
            <p>Please enter your new password and confirm it below.</p>
          </div>
          <form
            onSubmit={(e: any) => {
              e.preventDefault()
              const password = e.target.password.value
              const confirm_password = e.target.confirm_password.value
              if (!password) {
                notify('warn', 'Password is required')
                return
              }
              if (password !== confirm_password) {
                notify('warn', 'Password does not match')
                return
              }
              // Handle form submission
              handleSubmit({ password })
            }}
          >
            <div className='card'>
              <p>New Password</p>
              <input type='password' name='password' />
            </div>
            <div className='card'>
              <p>Confirm Password</p>
              <input type='password' name='confirm_password' />
            </div>
            <div className='card btn'>
              {loading ? (
                <FormLoader position='center' />
              ) : (
                <button type='submit'>Change Password</button>
              )}
            </div>
            <div className='card forgot '>
              <Link to='/users/login'>
                <p> Sign In</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
