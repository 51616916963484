import { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import { useGlobalContext } from '../../../../utils/Context'
import { SpecifyItem } from './components/SpecifyItem'
import { Newcountries, states } from '../../../../utils/Locals'
import StateData from '../../../../assets/data/StateData'
import moment from 'moment'
import { post, get, deleteAPI } from '../../../../utils/Api'
import FormLoader from '../../../../components/loader/FormLoader'
// import Paystack from '@paystack/inline-js'
import Modal from '../../../../components/modal/Modal'
import { useNavigate, useLocation } from 'react-router-dom'
import AutoComplete from '../../../../components/autoComplete/AutoComplete'
import CountryPhone from '../../../../assets/data/CountryPhone'
import PageLoader from '../../../../components/loader/PageLoader'

function Export() {
  const navigate = useNavigate()
  const { notify, profile, fetchUserProfile, profileLoading } =
    useGlobalContext()
  const [tab, setTab] = useState(1)
  const [delivery, setDelivery] = useState<any>({
    shipment_type: '',
    weight: '',
    length: '',
    width: '',
    pickup_date: '',
    height: '',
    description: '',
    city: '',
    email: '',
    state: '',
    value: '',
    // pickup_address: addval ? data[addval] : '',
    // pickup_hub: addval,
    pickup_number: '',
    // useDefaultAddress: auth?.address,
    country: 'NG',
    delivery_address: '',
    delivery_state: '',
    receiver_first_name: '',
    receiver_last_name: '',
    sender_first_name: '',
    sender_last_name: '',
    postcode: '',
    delivery_email: '',
    delivery_number: '',
    receiverCountry: '',
    Deliverypostcode: '',
    delivery_city: '',
    purpose: '',
    receiver_company: '',
    sender_company: '',
  })
  const [continues, setContinues] = useState(false)
  const [insurance, setInsurance] = useState(false)
  const [cityExists, setCityExists] = useState(true)
  const [firstCityExists, setFirstCityExists] = useState(true)
  const [manualFirstAddress, setManualFirstAddress] = useState(false)
  const [manualSecondAddress, setManualSecondAddress] = useState(false)

  const handleChange = ({ target }: any) => {
    setDelivery({ ...delivery, [target.name]: target.value })
  }
  const handleTab = (e: any) => {
    if (tab != 4) {
      e.preventDefault()
    }

    if (tab === 1) {
      const errors = []

      if (delivery.shipment_type === '') {
        errors.push('Shipment type')
      }
      if (delivery.purpose === '') {
        errors.push('purpose')
      }

      if (delivery.weight === '') {
        errors.push('Weight')
      }

      if (delivery.length === '') {
        errors.push('Length')
      }

      if (delivery.width === '') {
        errors.push('Width')
      }
      if (delivery.description.length > 70) {
        errors.push('Description is too long')
      }
      if (delivery.shipment_type === 'Document' && delivery.weight > 2) {
        errors.push(
          'You selected Document as a shipment type, weight must not be greater than 2'
        )
      }

      if (delivery.height === '') {
        errors.push('Height')
      }

      if (delivery.description === '') {
        errors.push('Description')
      }

      if (delivery.value === '') {
        errors.push('Value')
      }

      if (errors.length > 0) {
        return notify('error', `${errors.join(', ')} is required`)
      }
      setTab(2)
    }

    if (tab === 4) {
      getPrice()
    }

    if (tab === 2) {
      const errors = []
      if (delivery.sender_first_name === '') {
        errors.push('Sender First name')
      }
      if (delivery.sender_last_name === '') {
        errors.push('Sender Last name')
      }
      if (delivery.pickup_address === '') {
        errors.push('Pick up address')
      }

      if (delivery.country === '') {
        errors.push('Country')
      }
      if (delivery.country !== 'NG') {
        errors.push('Address must be in Nigeria')
      }
      if (delivery.pickup_address.length > 45) {
        errors.push('Pick up address is too long manually reduce it.')
      }
      if (delivery.state === '') {
        errors.push('State')
      }

      if (delivery.city === '') {
        errors.push('City')
      }

      if (delivery.postcode === '') {
        errors.push('Post code')
      }

      if (delivery.pickup_number === '') {
        errors.push('Phone number')
      }
      const phoneData: any = CountryPhone.find(
        (data: any) => data.isoCode === delivery.country
      )
      if (
        delivery.pickup_number.length < phoneData.phoneLength.min ||
        delivery.pickup_number.length > phoneData.phoneLength.max
      ) {
        errors.push('Phone number length needs to be valid')
      }
      if (errors.length > 0) {
        return notify('error', `${errors.join(', ')} is required to continue`)
      }
      setTab(3)
    }

    if (tab === 3) {
      // handleBooking();
      const errors = []

      if (delivery.receiver_first_name === '') {
        errors.push('Receiver name')
      }
      if (delivery.receiver_last_name === '') {
        errors.push('Receiver name')
      }

      if (delivery.delivery_address === '') {
        errors.push('Address')
      }
      if (delivery.delivery_address.length > 45) {
        errors.push('Pick up address is too long manually reduce it.')
      }
      if (delivery.receiverCountry === '') {
        errors.push('Country')
      }

      if (delivery.delivery_state === '') {
        errors.push('Receiver state')
      }

      if (delivery.delivery_city === '') {
        errors.push('Receiver City')
      }

      if (delivery.Deliverypostcode === '') {
        errors.push('Receiver Postcode')
      }

      if (delivery.delivery_email === '') {
        errors.push('Receiver email')
      }

      if (delivery.delivery_number === '') {
        errors.push('Receiver mobile number')
      }
      const phoneData: any = CountryPhone.find(
        (data: any) => data.isoCode === delivery.receiverCountry
      )
      if (
        delivery.delivery_number.length < phoneData.phoneLength.min ||
        delivery.delivery_number.length > phoneData.phoneLength.max
      ) {
        errors.push('Phone number length needs to be valid')
      }
      if (errors.length > 0) {
        return notify('error', `${errors.join(', ')} is required to continue`)
      }
      setTab(4)
    }

    if (tab === 5) {
      // BookDel()
    }
  }
  function showHeader() {
    switch (tab) {
      case 1:
        return 'Shipment Details'
      case 2:
        return 'PickUp Information'
      case 3:
        return 'Delivery Information'
      case 4:
        return 'Specify items in package'
      case 5:
        return 'Shipment Summary'
      default:
        return ''
    }
  }
  const [prohibitedItem, setProhibitedItem] = useState(false)

  //<-------------- Specific Items ----------->
  const [specifiedItems, setSpecifiedItems] = useState<any>([
    {
      itemName: '',
      description: '',
      itemQuantity: '',
      netValue: '',
      grossValue: '',
      itemValue: '',
      commodityCodes: { value: '', label: '' },
    },
  ])
  const handleSpecifiedItemSubmit = (data: any) => {
    // setTab()
    setSpecifiedItems(data)
    handleTab({})
  }

  //<--------------- Locations ----------->
  const [RuserStates, setRUserStates] = useState<any>([])
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  const fixAllCap = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  //<----------------- Get Cost --------------- >
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState<any>({
    shippingCost: 0,
    insurance: 0,
    totalPrice: 0,
  })
  const getPrice = async () => {
    const filterPickupCountry = Newcountries.filter((c) => {
      return c.isoCode == delivery.country
    })
    const filterreceiverCountry = Newcountries.filter((c) => {
      return c.isoCode == delivery.receiverCountry
    })
    let ApiUrl = ''
    if (delivery.shipment_type.toLocaleLowerCase() === 'document') {
      ApiUrl = `price/single?originCountryCode=${
        filterPickupCountry[0].isoCode
      }&originPostalCode=${
        delivery.postcode
      }&originCityName=${capitalizeFirstLetter(
        delivery.city
      )}&destinationCountryCode=${
        filterreceiverCountry[0].isoCode
      }&destinationPostalCode=${
        delivery.Deliverypostcode
      }&destinationCityName=${capitalizeFirstLetter(
        delivery.delivery_city
      )}&weight=${delivery.weight}&length=${delivery.length}&width=${
        delivery.width
      }&height=${delivery.height}&plannedShippingDate=${`${moment(
        new Date()
      ).format(
        'YYYY-MM-DD'
      )}`}&isCustomsDeclarable=false&nextBusinessDay=true&insurance=${insurance}&declaredValue=${
        delivery.value
      }`
    } else {
      ApiUrl = 'price/single'
    }

    let Newdata = {
      plannedShippingDateAndTime: `${moment(new Date()).format(
        'YYYY-MM-DD'
      )}T${moment(new Date()).format('hh:mm:ss')} ${new Date()
        .toLocaleDateString('en-US', {
          day: '2-digit',
          timeZoneName: 'short',
        })
        .slice(4)
        .slice(0, -2)}+01:00`,
      isCustomsDeclarable: true,
      document:
        delivery.shipment_type.toLocaleLowerCase() == 'document'
          ? 'document'
          : 'non_document',
      productCode: 'P',
      deliveryType: 'export',
      nextBusinessDay: true,
      insurance: insurance,
      customerDetails: {
        shipperDetails: {
          postalCode: delivery.postcode,
          cityName: capitalizeFirstLetter(delivery.city),
          countryCode: filterPickupCountry[0].isoCode,
          addressLine1: delivery.pickup_address,
          countyName: filterPickupCountry[0].name,
        },
        receiverDetails: {
          postalCode: delivery.Deliverypostcode,
          cityName: capitalizeFirstLetter(delivery.delivery_city),
          countryCode: filterreceiverCountry[0].isoCode,
          addressLine1: delivery.delivery_address,
          countyName: filterreceiverCountry[0].name,
        },
      },
      monetaryAmount: [
        {
          typeCode: 'declaredValue',
          value: Number(delivery.value),
          currency: 'NGN',
        },
      ],
      weight: Number(delivery.weight),
      length: Number(delivery.length),
      width: Number(delivery.width),
      height: Number(delivery.height),
    }
    setLoading(true)
    post(ApiUrl, Newdata, null)
      .then((result: any) => {
        if (result.status === 200) {
          setTotal(result?.data)
          setTab(5)
        } else {
          notify('error', result.message.detail)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  //<------------------------- Payment ------------------->
  /// payment
  // const popup = new Paystack()
  // const initiatePayment = async () => {
  //   const data = {
  //     amount: parseFloat(total).toFixed(2),
  //     usage: 'booking',
  //   }

  //   setLoading(true)
  //   post('payment/initialize', data, null)
  //     .then((result: any) => {
  //       if (result.status === 200) {
  //         notify('success', result.message)
  //         popup.newTransaction({
  //           key: 'pk_test_3de3e1d2ebe11f74d69ea6ff22341d681a7dc5d5', // Replace with your public Paystack key
  //           reference: result.data.reference,
  //           amount: parseFloat((parseFloat(total) * 100).toFixed(2)),
  //           email: profile.email,
  //           onSuccess: () => {
  //             // Redirect to the backend to verify payment
  //             verifyPayment(result.data.reference)
  //           },
  //           onCancel: () => {
  //             verifyPayment(result.data.reference)
  //           },
  //         })
  //       } else {
  //         notify('error', result.er.message)
  //       }
  //       setLoading(false)
  //     })
  //     .catch(() => {
  //       setLoading(false)
  //     })
  // }
  // hideLoader()
  // const verifyPayment = async (id: string) => {
  //   setLoading(true)
  //   get(`payment/verify/${id}`)
  //     .then((result: any) => {
  //       if (result.status === 200) {
  //         setLoading(false)
  //         handleBooking()
  //       } else {
  //         notify('error', result.message)
  //       }
  //     })
  //     .catch(() => {
  //       setLoading(false)
  //     })
  // }

  //<------------- Handle Booking ---------->
  const [modalConfirm, setModalConfirm] = useState(false)
  const [trackingId, setTrackingId] = useState<any>({})

  const handleBooking = async (paymentMethod: string) => {
    const filterPickupCountry = Newcountries.filter((c) => {
      return c.isoCode == delivery.country
    })

    const filterreceiverCountry = Newcountries.filter((c) => {
      return c.isoCode == delivery.receiverCountry
    })
    let sortSpecifiedItem = specifiedItems.map((itm: any, i: number) => {
      return {
        number: i + 1,
        quantity: {
          unitOfMeasurement: 'PCS',
          value: itm?.itemQuantity,
        },
        price: itm?.itemValue,
        description: itm?.description.trim(),
        weight: {
          netValue: itm?.grossValue,
          grossValue: itm?.grossValue,
        },
        commodityCodes: [
          {
            typeCode: 'outbound',
            value: itm.commodityCodes.value,
          },
        ],
        exportReasonType: 'permanent',
        manufacturerCountry: 'NG',
      }
    })
    const senderPhoneData: any = CountryPhone.find(
      (data: any) => data.isoCode === delivery.country
    )
    const recieverPhoneData: any = CountryPhone.find(
      (data: any) => data.isoCode === delivery.receiverCountry
    )
    const Newdata = {
      type: 'EXPORT',
      paymentMethod: paymentMethod,
      charge: parseFloat(total.totalPrice).toFixed(2),
      insurance: insurance,
      shipmentData: {
        plannedShippingDateAndTime: `${moment(new Date()).format(
          'YYYY-MM-DD'
        )}T${moment(new Date()).format('hh:mm:ss')} ${new Date()
          .toLocaleDateString('en-US', {
            day: '2-digit',
            timeZoneName: 'short',
          })
          .slice(4)
          .slice(0, -2)}+01:00`,
        pickup: false,
        // plannedShippingDateAndTime: `${moment(delivery.pickup_date)
        //   .utcOffset(0, false)
        //   .format()} `,
        declaredValue: delivery.value,
        sender: {
          postalAddress: {
            postalCode: delivery.postcode,
            cityName: capitalizeFirstLetter(delivery.city),
            countryCode: filterPickupCountry[0].isoCode,
            addressLine1: delivery.pickup_address,
            countyName: filterPickupCountry[0].name,
          },
          contactInformation: {
            phone: `${senderPhoneData.phoneCode}${delivery.pickup_number}`,
            companyName: delivery.sender_company,
            fullName: `${delivery.sender_first_name} ${delivery.sender_last_name}`,
            email: profile.email,
          },
        },
        receiver: {
          postalAddress: {
            postalCode: delivery.Deliverypostcode,
            cityName: capitalizeFirstLetter(delivery.delivery_city),
            countryCode: filterreceiverCountry[0].isoCode,
            addressLine1: delivery.delivery_address,
            countyName: filterreceiverCountry[0].name,
          },
          contactInformation: {
            phone: `${recieverPhoneData.phoneCode}${delivery.delivery_number}`,
            companyName: delivery.receiver_company,
            fullName: `${delivery.receiver_first_name} ${delivery.receiver_last_name}`,
            email: delivery.delivery_email,
          },
        },
        packages: [
          {
            weight: Number(delivery.weight),
            dimensions: {
              length: Number(delivery.length),
              width: Number(delivery.width),
              height: Number(delivery.height),
            },
          },
        ],
        description: delivery.description.trim(),
      },
      bookingData: {
        category: 'Eletronics',
        shipment_type: delivery.shipment_type.toUpperCase(),
        value: Number(delivery.value),
      },
      international: {
        exportDeclaration: {
          lineItems: sortSpecifiedItem,
          exportReason: delivery.purpose,
          // additionalCharges: [
          //   {
          //     value: 20,
          //     typeCode: "freight",
          //   },
          // ],

          // "2022-04-12"
          invoice: {
            number: `invoice number ${Math.random()}`,
            date: moment(new Date()).format('YYYY-MM-DD'),
          },
          placeOfIncoterm: delivery.delivery_city,
          exportReasonType: 'permanent',
          shipmentType: 'personal',
        },
      },
    }

    setLoading(true)

    post('booking/create', Newdata, null)
      .then((result: any) => {
        if (result.status === 200) {
          notify('success', result.message)
          setTrackingId(result.data)
          setModalConfirm(true)
        } else {
          notify(
            'error',
            typeof result.message === 'string'
              ? result.message
              : result.message.detail
          )
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchUserProfile()
  }, [])

  // <--------------------- Drafts ------------------->
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const draftId = searchParams.get('draftId')
  const [bookingLoading, setBookingLoading] = useState(false)

  useEffect(() => {
    if (draftId) {
      get(`booking/fetch-draft/${draftId}`)
        .then((result: any) => {
          if (result.status === 200) {
            setDelivery({
              ...delivery,
              ...result.data,
            })
            setSpecifiedItems(result.data.specifiedItems)
          } else {
            notify('error', result.message)
          }
        })
        .catch(() => {
          setBookingLoading(false)
        })
    }
  }, [draftId])
  const saveToDraft = async () => {
    setLoading(true)
    const draftData = {
      ...delivery,
      userId: profile._id,
      specifiedItems,
      type: 'Export',
      creatorName: `${profile.firstname} ${profile.lastname}`,
    }
    post('booking/create-draft', draftData, null)
      .then((result: any) => {
        if (result.status >= 200 && result.status < 300) {
          notify('success', result.message)
        } else {
          notify(
            'error',
            typeof result.message === 'string'
              ? result.message
              : result.message.detail
          )
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  async function deleteDraft() {
    deleteAPI(`booking/draft/${draftId}`)
      .then((result: any) => {
        if (result.status === 200) {
          notify('success', result.message)
          setTimeout(() => {
            navigate('/admin/bookings')
          }, 2000)
        } else {
          notify('error', result.message)
        }
      })
      .catch(() => {
        setBookingLoading(false)
      })
  }

  return bookingLoading ? (
    <div className='home'>
      <PageLoader position='center' />
    </div>
  ) : (
    <div className='delivery-page flex justify-center'>
      <div className='form-wrap'>
        <div
          className='back flex items-center'
          onClick={() => {
            if (tab === 1) {
              navigate('/user/home')
            } else {
              setTab(tab - 1)
            }
          }}
        >
          <Icon icon='nrk:back' />
          <p>{tab === 1 ? 'Back' : 'Previous Tab'}</p>
        </div>
        <div className='top'>
          <h2>{showHeader()}</h2>
        </div>
        {tab === 1 && (
          <div className='form'>
            <div className='input-card'>
              <select
                className='where-address-input-option'
                name='shipment_type'
                onChange={(e: any) => {
                  setDelivery({
                    ...delivery,
                    shipment_type: e.target.value,
                    value: e.target.value === 'Document' ? 1 : delivery.value,
                  })
                }}
                value={delivery.shipment_type}
                required
              >
                <option value='' style={{ color: 'rgba(36, 66, 46, 0.75)' }}>
                  Select Shipment Type
                </option>
                <option value='Document'>Document</option>
                <option value='Package'>Package</option>
              </select>
            </div>
            <div className='input-card'>
              <p>Weight (KG)</p>
              <input
                required
                type='number'
                name='weight'
                min='0.5'
                onChange={handleChange}
                value={delivery.weight}
              />
            </div>

            <div className='flex row justify-between'>
              <div className='input-card'>
                <p>Length (CM)</p>
                <input
                  required
                  type='number'
                  min='0.5'
                  name='length'
                  onChange={handleChange}
                  value={delivery.length}
                />
              </div>

              <div className='input-card'>
                <p>Width (CM)</p>
                <input
                  required
                  type='number'
                  name='width'
                  min='0.5'
                  onChange={handleChange}
                  value={delivery.width}
                />
              </div>
              <div className='input-card'>
                <p>Height (CM)</p>
                <input
                  required
                  type='number'
                  name='height'
                  // min="0.5"
                  value={delivery.height}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='input-card'>
              <p>Description</p>
              <input
                required
                type='text'
                placeholder='Give a detailed description of what you are shipping'
                name='description'
                onChange={handleChange}
                value={delivery.description}
              />
            </div>
            {delivery.shipment_type !== 'Document' && (
              <div className='input-card'>
                <p>Goods Value</p>
                <input
                  required
                  type='number'
                  placeholder=''
                  min='0.5'
                  name='value'
                  onChange={handleChange}
                  value={delivery.value}
                />
              </div>
            )}
            <div className='input-card'>
              <p>What is the purpose of your shipment?</p>
              <select
                className='where-address-input-option'
                name='purpose'
                onChange={(e: any) => {
                  setDelivery({
                    ...delivery,
                    purpose: e.target.value,
                  })
                }}
                value={delivery.purpose}
                required
              >
                <option value='' style={{ color: 'rgba(36, 66, 46, 0.75)' }}>
                  Select purpose
                </option>
                <option value='gift'>Gift</option>
                <option value='commercial'>Commercial</option>
                <option value='personal, not for resale'>
                  Personal, Not for Resale
                </option>
                <option value='sample'>Sample</option>
                <option value='return for repair'>Return for Repair</option>
                <option value='return after repair'>Return after Repair</option>
              </select>
            </div>
            <div className='input-card'>
              <button
                type='button'
                onClick={() => {
                  setProhibitedItem(true)
                }}
                className='btn-danger'
              >
                Prohibited Items
              </button>
            </div>
            <div className='btnsfd'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  id='flexCheckInsurance'
                  checked={insurance ? true : false}
                  onClick={() => {
                    setInsurance(!insurance)
                  }}
                />
                <label
                  className='form-check-label'
                  htmlFor='flexCheckInsurance'
                >
                  Shipment Protection: For just{' '}
                  {delivery.shipment_type === 'Document'
                    ? ` ₦7,000`
                    : `2% of
                  the total goods value (with a minimum charge of ₦10,000)`}
                  , you can ensure that your items are safeguarded against loss,
                  damage, or unforeseen incidents during transit.
                </label>
              </div>
            </div>
            <div className='btnsfd'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  id='flexCheckChecked'
                  checked={continues ? true : false}
                  onClick={() => {
                    setContinues(!continues)
                  }}
                />
                <label className='form-check-label' htmlFor='flexCheckChecked'>
                  By ticking the box, you agree that you have read and
                  understood the prohibited list
                </label>
              </div>
              <button
                onClick={handleTab}
                className='where-address-button'
                disabled={!continues}
              >
                Continue
              </button>
            </div>
          </div>
        )}
        {tab === 2 && (
          <div className='form'>
            <>
              <div className='flex justify-between  row'>
                <div className='input-card'>
                  <p>Sender First Name</p>
                  <input
                    required
                    type='text'
                    placeholder='Sender Name'
                    name='sender_first_name'
                    value={delivery.sender_first_name}
                    onChange={handleChange}
                  />
                </div>
                <div className='input-card'>
                  <p>Sender Last Name</p>
                  <input
                    required
                    type='text'
                    placeholder='Sender Name'
                    name='sender_last_name'
                    value={delivery.sender_last_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='input-card'>
                <p>Company</p>
                <input
                  required
                  type='text'
                  placeholder=''
                  name='sender_company'
                  value={delivery.sender_company}
                  onChange={handleChange}
                />
              </div>
              <AutoComplete
                onAddressSelect={(data: any) => {
                  setDelivery({
                    ...delivery,
                    pickup_address: data.address,
                    country: data.country,
                    state: data.state,
                    city: data.city,
                    postcode: data.postCode,
                  })
                  if (data.city === '') {
                    setFirstCityExists(false)
                  } else {
                    setFirstCityExists(true)
                  }
                }}
                tag='Pickup Address'
                address={
                  delivery.pickup_address
                    ? `${delivery.pickup_address || ''}, ${
                        delivery.city || ''
                      }, ${delivery.state || ''}, ${delivery.country || ''}`
                        .replace(/, ,+/g, ',')
                        .trim()
                    : ''
                }
                disabled={manualFirstAddress}
              />
              <div className='btnsfd'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='manualAddress'
                    checked={manualFirstAddress ? true : false}
                    onClick={() => {
                      setManualFirstAddress(!manualFirstAddress)
                      if (!manualFirstAddress) {
                        setFirstCityExists(false)
                      } else {
                        setFirstCityExists(true)
                      }
                    }}
                  />
                  <label className='form-check-label' htmlFor='manualAddress'>
                    Manually Input Address
                  </label>
                </div>
              </div>
              {manualFirstAddress && (
                <>
                  {' '}
                  <div className='flex row'>
                    <div className='input-card'>
                      <p>Select Country</p>

                      <select
                        className='where-address-input-option'
                        name='country'
                        value={delivery.country}
                        onChange={(e) => {
                          handleChange(e)
                        }}
                        required
                        disabled
                      >
                        <option value=''>Select country</option>
                        {Newcountries.map((data) => {
                          return (
                            <option value={data?.isoCode} key={data?.isoCode}>
                              {data?.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className='input-card'>
                      <p>Select state</p>

                      <select
                        className='where-address-input-option'
                        name='state'
                        onChange={async (e) => {
                          handleChange(e)
                        }}
                        value={delivery.state}
                        required
                      >
                        <option value=''>Select State</option>
                        {StateData.map((data) => {
                          return (
                            <option value={data?.state.name}>
                              {data?.state.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='input-card'>
                    <p>Address</p>
                    <input
                      required
                      type='text'
                      placeholder=''
                      name='pickup_address'
                      value={delivery.pickup_address}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
              {!firstCityExists && (
                <div className='input-card'>
                  <p>City</p>
                  <input
                    required
                    type='text'
                    placeholder='Input city'
                    name='city'
                    value={delivery.city}
                    onChange={handleChange}
                  />
                </div>
              )}
              {delivery.pickup_address && (
                <div className='input-card'>
                  <p>Post code</p>
                  <input
                    required
                    type='text'
                    placeholder='0039282'
                    onChange={({ target }) => {
                      setDelivery({
                        ...delivery,
                        postcode: target.value,
                      })
                    }}
                    value={delivery.postcode}
                  />
                </div>
              )}
              <div className='row'>
                {CountryPhone.filter(
                  (data: any) => data.isoCode === delivery.country
                ).map((data: any) => (
                  <div className='input-card' key={data.isoCode}>
                    <p>Phone Number</p>
                    <div className='flex items-center'>
                      <div className='flags flex items-center'>
                        <div className='flag-wrap flex items-center'>
                          <Icon
                            icon={`circle-flags:${data.isoCode.toLowerCase()}`}
                          />
                          <span>{data.phoneCode}</span>
                        </div>
                      </div>
                      <input
                        required
                        type='text'
                        placeholder='Input Phone Number'
                        name='pickup_number'
                        value={delivery.pickup_number}
                        onChange={handleChange}
                        min={data.phoneLength.min}
                        max={data.phoneLength.max}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
            <div className='btnsfd'>
              <button onClick={handleTab} className='where-address-button'>
                Continue
              </button>
            </div>
          </div>
        )}
        {tab === 3 && (
          <div className='form'>
            <>
              <div className='flex justify-between  row'>
                <div className='input-card'>
                  <p>Receiver First Name</p>
                  <input
                    required
                    type='text'
                    placeholder='Receiver First Name'
                    name='receiver_first_name'
                    value={delivery.receiver_first_name}
                    onChange={handleChange}
                  />
                </div>
                <div className='input-card'>
                  <p>Receiver Last Name</p>
                  <input
                    required
                    type='text'
                    placeholder='Receiver Last Name'
                    name='receiver_last_name'
                    value={delivery.receiver_last_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='input-card'>
                <p>Company</p>
                <input
                  required
                  type='text'
                  placeholder=''
                  name='receiver_company'
                  value={delivery.receiver_company}
                  onChange={handleChange}
                />
              </div>
              <AutoComplete
                onAddressSelect={(data: any) => {
                  setDelivery({
                    ...delivery,
                    delivery_address: data.address,
                    receiverCountry: data.country,
                    delivery_state: data.state,
                    delivery_city: data.city,
                    Deliverypostcode: data.postCode,
                  })
                  if (data.city === '') {
                    setCityExists(false)
                  } else {
                    setCityExists(true)
                  }
                  setSpecifiedItems([
                    {
                      itemName: '',
                      description: '',
                      itemQuantity: '',
                      netValue: '',
                      grossValue: '',
                      itemValue: '',
                      commodityCodes: { value: '', label: '' },
                    },
                  ])
                }}
                tag='Delivery Address'
                address={
                  delivery.delivery_address
                    ? `${delivery.delivery_address || ''}, ${
                        delivery.delivery_city || ''
                      }, ${delivery.delivery_state || ''}, ${
                        delivery.receiverCountry || ''
                      }`
                        .replace(/, ,+/g, ',')
                        .trim()
                    : ''
                }
                disabled={manualSecondAddress}
              />
              <div className='btnsfd'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='manualAddress2'
                    checked={manualSecondAddress ? true : false}
                    onClick={() => {
                      setManualSecondAddress(!manualSecondAddress)
                      if (!manualSecondAddress) {
                        setCityExists(false)
                        if (delivery.receiverCountry) {
                          setRUserStates(states(delivery.receiverCountry))
                        }
                      } else {
                        setCityExists(true)
                      }
                    }}
                  />
                  <label className='form-check-label' htmlFor='manualAddress2'>
                    Manually Input Address
                  </label>
                </div>
              </div>
              {manualSecondAddress && (
                <>
                  <div className='flex row'>
                    <div className='input-card'>
                      <p>Country</p>
                      <select
                        className='where-address-input-option'
                        name='receiverCountry'
                        value={delivery.receiverCountry}
                        required
                        onChange={(e) => {
                          handleChange(e)
                          setRUserStates(states(e.target.value))
                        }}
                      >
                        <option value=''>Select country</option>
                        {Newcountries.map((data) => {
                          return (
                            <option value={data?.isoCode}>{data?.name}</option>
                          )
                        })}
                      </select>
                    </div>

                    <div className='input-card'>
                      <p>Add State</p>

                      <select
                        className='where-address-input-option'
                        onChange={async (e) => {
                          handleChange(e)
                        }}
                        name='delivery_state'
                        value={delivery.delivery_state}
                        required
                      >
                        <option value=''>Select State</option>
                        {RuserStates.map((data: any) => {
                          return (
                            <option value={data?.name}>{data?.name}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='input-card'>
                    <p>Delivery Address</p>
                    <input
                      required
                      type='text'
                      placeholder=''
                      name='delivery_address'
                      value={delivery.delivery_address}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
              {!cityExists && (
                <div className='input-card'>
                  <p>City</p>
                  <input
                    required
                    type='text'
                    placeholder='Input city'
                    name='delivery_city'
                    value={delivery.delivery_city}
                    onChange={handleChange}
                  />
                </div>
              )}
              {delivery.delivery_address && (
                <div className='input-card'>
                  <p>Post code</p>
                  <input
                    required
                    type='text'
                    placeholder='0039282'
                    onChange={({ target }) => {
                      setDelivery({
                        ...delivery,
                        Deliverypostcode: target.value,
                      })
                    }}
                    value={delivery.Deliverypostcode}
                  />
                </div>
              )}

              <div className='row'>
                {CountryPhone.filter(
                  (data: any) => data.isoCode === delivery.receiverCountry
                ).map((data: any) => (
                  <div className='input-card' key={data.isoCode}>
                    <p>Phone Number</p>
                    <div className='flex items-center'>
                      <div className='flags flex items-center'>
                        <div className='flag-wrap flex items-center'>
                          <Icon
                            icon={`circle-flags:${data.isoCode.toLowerCase()}`}
                          />
                          <span>{data.phoneCode}</span>
                        </div>
                      </div>
                      <input
                        required
                        type='text'
                        placeholder='Input Phone Number'
                        name='delivery_number'
                        value={delivery.delivery_number}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className='row flex'>
                <div className='input-card'>
                  <p>Delivery Email</p>
                  <input
                    required
                    type='text'
                    placeholder='Input Email'
                    name='delivery_email'
                    value={delivery.delivery_email}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </>

            <div className='btnsfd'>
              <button onClick={handleTab} className='where-address-button'>
                Continue
              </button>
            </div>
          </div>
        )}
        {tab === 4 && (
          <SpecifyItem
            handleSpecifiedItemSubmit={handleSpecifiedItemSubmit}
            specifiedItems={specifiedItems}
            country={delivery.receiverCountry}
            loading={loading}
            delivery={delivery}
          />
        )}
        {tab === 5 && (
          <div className='sumaryWrap'>
            <div className='desc'>
              <div className='flex justify-between items-center'>
                <h2>Order Description</h2>
                <p>{delivery.description}</p>
              </div>
              <div className='flex justify-between items-center'>
                <h2>WEIGHT</h2>
                <p>{delivery.weight}KG</p>
              </div>
              <div className='flex justify-between items-center'>
                <h2>LENGTH</h2>
                <p>{delivery.length}CM</p>
              </div>
              <div className='flex justify-between items-center'>
                <h2>WIDTH</h2>
                <p>{delivery.width}CM</p>
              </div>
              <div className='flex justify-between items-center'>
                <h2>HEIGHT</h2>
                <p>{delivery.height}CM</p>
              </div>
            </div>
            <div className='databdd'>
              <div className='flex justify-between items-center'>
                <h5>Sender Name</h5>
                <p>
                  {delivery.sender_first_name} {delivery.sender_last_name}
                </p>
              </div>
              <div className='flex justify-between items-center'>
                <h5>Sender Number</h5>
                <p> {delivery.pickup_number}</p>
              </div>
            </div>

            <div className='databdd'>
              <div className='flex justify-between items-center'>
                <h5>Delivery Location </h5>
                <p>
                  {delivery.delivery_address} {delivery.delivery_city}{' '}
                  {delivery.delivery_state}
                </p>
              </div>
              <div className='flex justify-between items-center'>
                <h5>Recipient Name</h5>
                <p>
                  {' '}
                  {delivery.receiver_first_name} {delivery.receiver_last_name}
                </p>
              </div>
              <div className='flex justify-between items-center'>
                <h5>Recipient Number</h5>
                <p>{delivery.delivery_number}</p>
              </div>
            </div>

            <div className='acceptText'>
              <p>
                By continuing, I represent that the declaration above is a
                proper and accurate description of the contents of my package.
              </p>
            </div>

            <div className='other flex justify-between items-center'>
              <h3> Shipping Cost</h3>
              <h3>
                ₦
                {parseFloat(total.shippingCost).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h3>
            </div>
            <div className='other flex justify-between items-center'>
              <h3> Shipment Protection</h3>
              <h3>
                ₦
                {parseFloat(total.insurance).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h3>
            </div>
            {total.breakDown.extraCharges.map((data: any) => (
              <div className='other flex justify-between items-center'>
                <h3>{fixAllCap(data.name)}</h3>
                <h3>
                  ₦
                  {parseFloat(data.price).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h3>
              </div>
            ))}
            {total.breakDown.discountAmount > 0 && (
              <div className='other flex justify-between items-center'>
                <h3> Discount</h3>
                <h3>
                  -₦
                  {parseFloat(total.breakDown.discountAmount).toLocaleString(
                    'en-US',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </h3>
              </div>
            )}
            <div className='cost flex justify-between items-center'>
              <h3> Total Cost</h3>
              <h3 className='price'>
                ₦
                {parseFloat(total.totalPrice).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h3>
            </div>
            {profileLoading ? (
              <FormLoader position='center' />
            ) : (
              <div className='databdd'>
                <div className='flex justify-between items-center'>
                  <h5>Wallet Balance</h5>
                  <p>
                    {parseFloat(
                      parseFloat(profile.balance).toFixed(2)
                    ).toLocaleString()}
                  </p>
                </div>
                {parseFloat(total.totalPrice).toFixed(2) > profile?.balance && (
                  <>
                    <p className='red'>Insufficent Amount. Fund wallet</p>
                    <div className='flex justify-between items-center'>
                      <h5>Account Name</h5>
                      <p>
                        {profile.firstname} {profile.lastname}
                      </p>
                    </div>
                    <div className='flex justify-between items-center'>
                      <h5>Account Number</h5>
                      <p>{profile.account_number}</p>
                    </div>
                    <div className='flex justify-between items-center'>
                      <h5>Bank </h5>
                      <p>{profile.bank}</p>
                    </div>
                    <button onClick={fetchUserProfile}>Refresh account</button>
                  </>
                )}
              </div>
            )}
            <div>
              {loading ? (
                <FormLoader position='center' />
              ) : (
                <div className='paymentTotalbtn'>
                  <button
                    onClick={() => {
                      parseFloat(total.totalPrice).toFixed(2) > profile?.balance
                        ? () => {}
                        : handleBooking('wallet')
                    }}
                    disabled={
                      parseFloat(total.totalPrice).toFixed(2) > profile?.balance
                    }
                  >
                    Pay with Wallet
                  </button>
                  {profile.turnoff_payment &&
                    parseFloat(total.totalPrice).toFixed(2) >
                      profile?.balance && (
                      <button
                        className='dif'
                        onClick={() => {
                          handleBooking('credit')
                        }}
                      >
                        Proceed on credit
                      </button>
                    )}
                  <button
                    className='dif'
                    onClick={() => {
                      saveToDraft()
                    }}
                  >
                    Save to Draft
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {modalConfirm && (
          <Modal position='center'>
            <div className='success-modal'>
              <h1>Booking Successful</h1>
              <p>
                Track ID: <b>#{trackingId?.shipmentMeta?.trackingId}</b>
              </p>
              <button onClick={() => navigate('/user/bookings')}>
                Go to Bookings
              </button>
              {draftId && (
                <button onClick={deleteDraft} className='delete'>
                  Delete draft
                </button>
              )}
            </div>
          </Modal>
        )}
        {prohibitedItem && (
          <Modal position='center'>
            <div className='prohibit-modal'>
              <h2>The underlisted items are Prohibited Items</h2>
              <div className='probitrbody'>
                <ul>
                  <li>
                    Our Courier partners do not have the operational capability
                    to transport such items.
                  </li>

                  <li>
                    Animal or animal’s products contained within Appendix 1 of
                    CITES are prohibited from carriage. The CITES Convention
                    (Convention on International Trade in Endangered Species of
                    Wild Fauna and Flora) is an international agreement between
                    governments concerning the international trade in specimens
                    of wild animals or plants https://www.cites.org/eng. Animal
                    or animal’s products not contained within Appendix 1 of
                    CITES can usually be shipped but may require an export
                    license from the issuing government. See under restricted.
                  </li>

                  <li>
                    These items are prohibited for carriage as the network is
                    not designed to be sufficiently secure for such
                    transportation. It could lead to our Courier partner’s
                    people or property becoming targets of criminal activity.{' '}
                  </li>

                  <li>
                    These items are prohibited for carriage as the network is
                    not designed to be sufficiently secure for such
                    transportation. It could lead to our Courier partner’s
                    people or property becoming targets of criminal activity.
                    The relevant local Authority is to be notified if the amount
                    of cash found exceeds the relevant threshold for
                    notification. - Travellers Cheques & Activated Credit Cards
                    As an exception, certain selected and approved customers who
                    have significant volumes of core network shipments may be
                    permitted to transport Travellers Cheques & Activated Credit
                    Cards (GSOP Shipment and Product Handling Policy and
                    Standards 2.2.14 Travellers Cheques & Activated Credit
                    Cards).
                  </li>

                  <li>
                    These items are prohibited for carriage as our Courier
                    partner’s network is not designed to be sufficiently secure
                    for such transportation. It could lead to its people or
                    property becoming targets of criminal activity.
                  </li>
                  <li>
                    These items are prohibited for carriage in our Courier
                    partner’s network as in many territories special handling
                    and licenses are required for the carrier. Restrictions also
                    apply in airports worldwide for the airside handling of such
                    items. Under x-ray replica firearms resemble real guns and
                    may cause disruption and delay in the screening process.
                  </li>

                  <li>
                    Any goods considered to be illegal are prohibited for
                    carriage as our Courier partners could be held financially
                    and criminally responsible for the movement of such goods.
                    This can include items that are country-specific (e.g. the
                    importation of alcohol and pornography including sex-dolls)
                    and commodities considered to be illegal internationally,
                    such as counterfeit goods and narcotics (e.g. Heroin,
                    Cocaine, Fentanyl and chemicals that may be precursors to
                    narcotic manufacture).
                  </li>
                  <li>
                    Counterfeit goods in breach of intellectual property rights
                    (IPR) are prohibited and carrying them may cause serious
                    harm to the reputation of our Courier partners. As per our
                    policy, all IPR / counterfeit goods and commodities are
                    illegal and are therefore prohibited for carriage.
                  </li>
                </ul>
                <button onClick={() => setProhibitedItem(false)}>Close</button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  )
}

export default Export
