import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import { AppProvider } from './utils/Context.tsx'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://5a40f55a077796e77f48793e6144e222@o4508931235381248.ingest.de.sentry.io/4508931257008208',
})
createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </StrictMode>
)
