import { useState, useEffect } from 'react'
import { get } from '../../../../utils/Api'
import { useGlobalContext } from '../../../../utils/Context'
import moment from 'moment'
import FormLoader from '../../../../components/loader/FormLoader'
import { Icon } from '@iconify/react'
import { useParams } from 'react-router-dom'

function Tracking() {
  const { notify } = useGlobalContext()
  const { id } = useParams()
  const [trackingLoading, setTrackingLoading] = useState(true)
  const [trackingData, setTrackingData] = useState<any>({ events: [] })

  const fetchTracking = async () => {
    setTrackingLoading(true)
    get(`tracking/single?id=${id}`)
      .then((result: any) => {
        if (result.status === 200) {
          setTrackingData(result.data.shipments[0])
        } else {
          notify('error', result.er.message)
        }
        setTrackingLoading(false)
      })
      .catch(() => {
        setTrackingLoading(false)
        setTrackingData({ events: [] })
        notify('error', 'Tracking Id is invalid')
      })
  }
  useEffect(() => {
    fetchTracking()
  }, [id])

  return (
    <div className='home'>
      <div className='home_tables'>
        {trackingLoading ? (
          <FormLoader position='center' />
        ) : (
          <div className='left'>
            {trackingData?.events.length > 0 ? (
              <>
                {' '}
                <table>
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trackingData.events.map((item: any, i: number) => (
                      <tr key={i + 1}>
                        <td>{1 + i}</td>
                        <td>
                          {moment(item.date).format('Do MMM, YYYY')} at{' '}
                          {moment(item.time, 'HH:mm:ss').format('h:mma')}
                        </td>
                        <td>{item.description}</td>
                        <td>{item.serviceArea[0].description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className='mobile-table'>
                  {trackingData.events.map((item: any, i: number) => (
                    <div className='card'>
                      <div className='flex justify-between row'>
                        <div className='count flex items-center justify-center'>
                          {i + 1}
                        </div>
                        <p>
                          {' '}
                          {moment(item.date).format('Do MMM, YYYY')} at{' '}
                          {moment(item.time, 'HH:mm:ss').format('h:mma')}
                        </p>
                      </div>
                      <div className='row flex justify-between flex-wrap'>
                        <h4>Description</h4>
                        <p>{item.description}</p>
                      </div>
                      <div className='row flex justify-between'>
                        <h4>Location</h4>
                        <p>{item.serviceArea[0].description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className='empty flex justify-center'>
                <div className='empty-cover'>
                  <Icon icon='material-symbols-light:track-changes-rounded' />
                  <h1>No Tracking Information To Show</h1>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Tracking
