import { useState, useEffect } from 'react'
import { get } from '../../utils/Api'
import { useGlobalContext } from '../../utils/Context'
import { Icon } from '@iconify/react'
import FormLoader from '../loader/FormLoader'
import { useNavigate, useLocation } from 'react-router-dom'
import Pagination from '../pagination/Pagination'

function Drafts() {
  const { notify, profile } = useGlobalContext()
  const navigate = useNavigate()
  const [customersList, setCustomersList] = useState<any>({ data: [] })
  const [bookingsLoading, setBookingsLoading] = useState(true)
  const [search, setSearch] = useState('')
  const location = useLocation()
  const pathSegments = location.pathname.split('/')

  function fetchCustomers(page: number) {
    setBookingsLoading(true)
    let url = ''
    if (search) {
      url = `booking/fetch-drafts?userId=${profile._id}&search=${search}&page=${page}&limit=10`
    } else {
      url = `booking/fetch-drafts?userId=${profile._id}&page=${page}&limit=10`
    }
    get(url)
      .then((result: any) => {
        if (result.status === 200) {
          setCustomersList(result)
        } else {
          notify('error', result.message)
        }
        setBookingsLoading(false)
      })
      .catch(() => {
        setBookingsLoading(false)
      })
  }

  useEffect(() => {
    fetchCustomers(1)
  }, [])

  //<----------- Download Excel and CSV --------------->
  //
  return (
    <div className='home'>
      <div className='home_tables'>
        <div className='left'>
          {bookingsLoading ? (
            <FormLoader position='center' />
          ) : (
            <>
              <div className='flex justify-between items-center search-btns flex-wrap'>
                <div className='search flex flex-wrap'>
                  <input
                    type='search'
                    name=''
                    id=''
                    value={search}
                    placeholder='search by names or addresses'
                    onChange={(e) => {
                      setSearch(e.target.value)
                    }}
                  />{' '}
                  <button
                    onClick={() => {
                      fetchCustomers(1)
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              {customersList.data.length > 0 ? (
                <>
                  <div className='mobile-table'>
                    {customersList.data.map((data: any, i: number) => (
                      <div
                        className='card'
                        onClick={() =>
                          navigate(
                            `/${
                              pathSegments[1]
                            }/${data.type.toLowerCase()}?draftId=${data._id}`
                          )
                        }
                      >
                        <div className='flex justify-between row'>
                          <div className='count flex items-center justify-center'>
                            {i + 1}
                          </div>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>type</h4>
                          <p>{data.type}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Shipment type</h4>
                          <p>{data.shipment_type}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Weight</h4>
                          <p>{data.weight}kg</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Pickup address</h4>
                          <p>
                            {data.pickup_address} {data.city}
                          </p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Delivery address</h4>
                          <p>
                            {data.delivery_address} {data.delivery_city}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <table className='desktop-table'>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Type</th>
                        <th>Shipment Type</th>
                        <th>Weight</th>
                        <th>Pickup Address</th>
                        <th>Delivery Address</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {customersList.data.map((data: any, i: number) => (
                        <tr key={data._id}>
                          <td>{i + 1}</td>
                          <td>{data.type}</td>
                          <td>{data.shipment_type}</td>
                          <td>{data.weight}kg</td>
                          <td>
                            {data.pickup_address} {data.city}
                          </td>
                          <td>
                            {data.delivery_address} {data.delivery_city}
                          </td>
                          <td className='icons dif'>
                            <span
                              className='PU'
                              onClick={() =>
                                navigate(
                                  `/${
                                    pathSegments[1]
                                  }/${data.type.toLowerCase()}?draftId=${
                                    data._id
                                  }`
                                )
                              }
                            >
                              book
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    className='pagination-bar'
                    currentPage={customersList.pagination.page}
                    totalCount={customersList.pagination.totalPages}
                    pageSize={customersList.pagination.limit}
                    onPageChange={(page: number) => {
                      fetchCustomers(page)
                    }}
                  />
                </>
              ) : (
                <div className='empty flex justify-center'>
                  <div className='empty-cover'>
                    <Icon icon='majesticons:book-line' />
                    <h1>No Drafts To Show</h1>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Drafts
