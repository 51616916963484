import { useEffect, useState } from 'react'
import { useGlobalContext } from '../../../../utils/Context'
import PageLoader from '../../../../components/loader/PageLoader'
import { Newcountries, states } from '../../../../utils/Locals'
import { put } from '../../../../utils/Api'
import FormLoader from '../../../../components/loader/FormLoader'

function Settings() {
  const { profile, fetchUserProfile, profileLoading, notify } =
    useGlobalContext()
  const [edit, setEdit] = useState('')
  const [addressData, setAddressData] = useState({
    country: '',
    state: '',
    city: '',
    address: '',
  })
  const [nStates, setNStates] = useState<any>([])
  const [addressLoading, setAddressLoading] = useState(false)
  useEffect(() => {
    fetchUserProfile()
    setAddressData({
      country: profile.country,
      state: profile.state,
      city: profile.city,
      address: profile.address,
    })
    setNStates(states(profile.country))
  }, [])
  async function handleAddressSubmit() {
    setAddressLoading(true)
    if (
      !addressData.country ||
      !addressData.state ||
      !addressData.city ||
      !addressData.address
    ) {
      alert('Please fill in all address fields')
      setAddressLoading(false)
      return
    }
    const res = await put(`user/change-address`, addressData)

    if (res.status === 200) {
      setAddressLoading(false)
      notify('success', 'Address updated successfully')
      fetchUserProfile()
      setEdit('')
    } else {
      notify('error', res.message)
    }

    setAddressLoading(false)
  }

  //Password Setting
  const [password, setPassword] = useState({
    oldPassword: '********',
    newPassword: '',
    confirmPassword: '',
  })
  const [passwordLoading, setPasswordLoading] = useState(false)

  async function handlePasswordSubmit() {
    setPasswordLoading(true)
    if (
      !password.oldPassword ||
      !password.newPassword ||
      !password.confirmPassword
    ) {
      alert('Please fill in all address fields')
      setPasswordLoading(false)
      return
    }
    if (password.newPassword !== password.confirmPassword) {
      alert(`Passwords Doesn't match`)
      setPasswordLoading(false)
      return
    }
    const res = await put(`user/change-password`, {
      oldPassword: password.oldPassword,
      newPassword: password.newPassword,
    })

    if (res.status === 200) {
      setPasswordLoading(false)
      notify('success', 'Password updated successfully')
      fetchUserProfile()
      setEdit('')
      setPassword({
        oldPassword: '********',
        newPassword: '',
        confirmPassword: '',
      })
    } else {
      notify('error', res.message)
    }

    setPasswordLoading(false)
  }

  return profileLoading ? (
    <div className='home'>
      <PageLoader position='center' />{' '}
    </div>
  ) : (
    <div className='home bg-white flex justify-center'>
      <div className='settings-wrap'>
        <div className='card-wrap'>
          <h2>Account Settings</h2>
          <div className='flex justify-between flex-wrap'>
            <div className='card'>
              <div className='card-inner'>
                <p>FIRST NAME</p>
                <input type='text' disabled value={profile.firstname} />
              </div>
            </div>
            <div className='card'>
              <div className='card-inner'>
                <p>LAST NAME</p>
                <input type='text' disabled value={profile.lastname} />
              </div>
            </div>
            <div className='card'>
              <div className='card-inner'>
                <p>PHONE NUMBER</p>
                <input type='text' disabled value={profile.phonenumber} />
              </div>
            </div>
            <div className='card'>
              <div className='card-inner'>
                <p>EMAIL</p>
                <input
                  type='text'
                  disabled
                  value={profile.email}
                  className='dif'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-wrap'>
          <h2>Address</h2>
          <div className='flex justify-between flex-wrap'>
            <div className='card'>
              <div className='card-inner'>
                <p>COUNTRY</p>
                {edit !== 'address' ? (
                  <input type='text' disabled value={profile.country} />
                ) : (
                  <select
                    className='where-address-input-option'
                    name='country'
                    value={addressData.country}
                    onChange={(e) => {
                      setAddressData({
                        ...addressData,
                        country: e.target.value,
                      })
                      setNStates(states(e.target.value))
                    }}
                    required
                  >
                    <option value=''>Select country</option>
                    {Newcountries.map((data) => {
                      return (
                        <option value={data?.isoCode} key={data?.isoCode}>
                          {data?.name}
                        </option>
                      )
                    })}
                  </select>
                )}
              </div>
            </div>
            <div className='card'>
              <div className='card-inner'>
                <p>STATE</p>
                {edit !== 'address' ? (
                  <input type='text' disabled defaultValue={profile.state} />
                ) : (
                  <select
                    className='where-address-input-option'
                    onChange={async (e) => {
                      setAddressData({ ...addressData, state: e.target.value })
                    }}
                    name='delivery_state'
                    value={addressData.state}
                    required
                  >
                    <option value=''>Select State</option>
                    {nStates.map((data: any) => {
                      return (
                        <option value={data?.name} key={data?.name}>
                          {data?.name}
                        </option>
                      )
                    })}
                  </select>
                )}
              </div>
            </div>
            <div className='card'>
              <div className='card-inner'>
                <p>CITY</p>
                <input
                  type='text'
                  disabled={edit !== 'address'}
                  value={addressData.city}
                  onChange={(e) =>
                    setAddressData({ ...addressData, city: e.target.value })
                  }
                />
              </div>
            </div>
            <div className='card'>
              <div className='card-inner'>
                <p>ADDRESS LINE</p>
                <input
                  type='text'
                  disabled={edit !== 'address'}
                  value={addressData.address}
                  onChange={(e) =>
                    setAddressData({ ...addressData, address: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          {addressLoading ? (
            <FormLoader position='center' />
          ) : (
            <>
              {edit === 'address' ? (
                <div className='flex buttons justify-center'>
                  <button className='cancel' onClick={() => setEdit('')}>
                    Cancel
                  </button>
                  <button onClick={() => handleAddressSubmit()}>Save</button>
                </div>
              ) : (
                <div className='flex buttons justify-center'>
                  <button onClick={() => setEdit('address')}>Edit</button>
                </div>
              )}
            </>
          )}
        </div>
        <div className='card-wrap'>
          <h2>Password Settings</h2>
          <div className='flex justify-between flex-wrap'>
            <div className='card'>
              <div className='card-inner'>
                <p>PASSWORD</p>
                <input
                  type='text'
                  disabled={edit !== 'password'}
                  value={password.oldPassword}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      oldPassword: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            {edit === 'password' && (
              <>
                <div className='card'>
                  <div className='card-inner'>
                    <p>NEW PASSWORD</p>
                    <input
                      type='text'
                      value={password.newPassword}
                      onChange={(e) =>
                        setPassword({
                          ...password,
                          newPassword: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className='card'>
                  <div className='card-inner'>
                    <p>Confirm Password</p>
                    <input
                      type='text'
                      value={profile.confirmPassword}
                      onChange={(e) =>
                        setPassword({
                          ...password,
                          confirmPassword: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {passwordLoading ? (
            <FormLoader position='center' />
          ) : (
            <>
              {edit === 'password' ? (
                <div className='flex buttons justify-center'>
                  <button
                    className='cancel'
                    onClick={() => {
                      setEdit('')
                      setPassword({
                        ...password,
                        oldPassword: '********',
                      })
                    }}
                  >
                    Cancel
                  </button>
                  <button onClick={() => handlePasswordSubmit()}>Save</button>
                </div>
              ) : (
                <div className='flex buttons justify-center'>
                  <button
                    onClick={() => {
                      setEdit('password')
                      setPassword({
                        ...password,
                        oldPassword: '',
                      })
                    }}
                  >
                    Change Password
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Settings
