import { useState } from 'react'
import './Auth.scss'
import image from '../../assets/images/4.jpeg'
import FormLoader from '../../components/loader/FormLoader'
import { Link, useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../utils/Context'
import { post, postNo, putNo } from '../../utils/Api'
function Login() {
  const { notify } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [tab, setTab] = useState(1)
  const [auth, setAuth] = useState<any>({})
  const navigate = useNavigate()

  function handleSubmit(data: any) {
    setAuth(data)
    setLoading(true)
    postNo('auth/login', data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.user.role === 'user') {
            notify('warn', 'Please login to the user portal.')
            setTimeout(() => {
              navigate('/users/login')
            }, 2000)
            return
          }
          const user = {
            firstname: res?.data?.user?.firstname,
            referral: res?.data?.user?.referral,
            email: res?.data?.user?.email,
            lastname: res?.data?.user?.lastname,
            address: res?.data?.user?.address,
            id: res?.data?.user?._id,
            phonenumber: res?.data?.user?.phonenumber,
          }
          sessionStorage.setItem('user', JSON.stringify(user))
          setToken(res?.data?.access_token)
          sessionStorage.setItem('token', res?.data?.access_token)
          sessionStorage.setItem('refreshToken', res?.data?.refresh_tokens)
          if (res.data.message === 'verify email before you login') {
            notify('warn', 'Verify account')
            resendOTP(data, res?.data?.access_token)
          } else {
            setLoading(false)
            notify('success', res.message)
            navigate('/aggregator/home')
          }
        } else {
          notify('error', res.message)
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const resendOTP = async (data: any, token: string) => {
    setLoading(true)
    const res = await post(
      `user/resend-otp`,
      {
        email: data.account,
      },
      token
    )

    if (res.status === 200) {
      setLoading(false)
      notify('success', 'OTP Sent')
      setTab(2)
    } else {
      notify('error', res?.message)
    }
  }
  function handleVerify() {
    const submit = {
      code: token,
      email: auth.account,
    }
    if (!token) {
      notify('warn', 'token is required')
      return
    }
    setLoading(true)
    putNo('user/verify-email', submit)
      .then((res) => {
        if (res.status === 200) {
          notify('success', res.message)
          navigate('/aggregator/home')
        } else {
          notify('error', res.message)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  return (
    <div className='auth flex'>
      <div className='left'>
        <img src={image} alt='delivery man image' />
      </div>
      <div className='right flex items-center'>
        {tab === 1 ? (
          <div className='wrap'>
            <div className='top'>
              <h1>Aggregator Login</h1>
            </div>
            <form
              onSubmit={(e: any) => {
                e.preventDefault()
                const account = e.target.account.value
                const password = e.target.password.value
                if (!account || !password) {
                  notify('warn', 'Please fill in all fields')
                  return
                }
                // Handle form submission
                handleSubmit({ account: account.toLowerCase(), password })
              }}
            >
              <div className='card'>
                <p>Email</p>
                <input type='email' name='account' />
              </div>
              <div className='card'>
                <p>Password</p>
                <input type='password' name='password' />
              </div>
              <div className='card btn'>
                {loading ? (
                  <FormLoader position='center' />
                ) : (
                  <button type='submit'>Sign In</button>
                )}
              </div>
              <div className='card forgot flex'>
                <Link to='/users/login'>
                  <p>User?</p>
                </Link>
                <Link to='/forgot-password'>
                  <p>Forgot Password?</p>
                </Link>
              </div>
            </form>
          </div>
        ) : (
          <div className='wrap'>
            <div className='top'>
              <h1>Verify OTP</h1>
            </div>
            <div className='card'>
              <p>OTP</p>
              <input
                type='tel'
                name='token'
                onChange={(e) => setToken(e.target.value)}
              />
            </div>
            <div className='card btn'>
              {loading ? (
                <FormLoader position='center' />
              ) : (
                <button onClick={handleVerify}>Verify Email</button>
              )}
            </div>
            <div className='card forgot '>
              <Link to='/'>
                <h4>Forgot Password?</h4>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Login
