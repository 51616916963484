import { useState, useEffect } from 'react'
import { get } from '../../../../utils/Api'
import { useGlobalContext } from '../../../../utils/Context'
import { Icon } from '@iconify/react'
import FormLoader from '../../../../components/loader/FormLoader'
import { useNavigate } from 'react-router-dom'
import Pagination from '../../../../components/pagination/Pagination'

function Bookings() {
  const { notify } = useGlobalContext()
  const navigate = useNavigate()
  const [bookings, setBookings] = useState<any>({ data: [] })
  const [bookingsLoading, setBookingsLoading] = useState(true)
  const [paginationPage, setPaginationPage] = useState('default')

  function fetchBookings(page: number) {
    setBookingsLoading(true)
    get(`booking/list?page=${page}&limit=10`)
      .then((result: any) => {
        if (result.status === 200) {
          setBookings(result)
        } else {
          notify('error', result.message)
        }
        setBookingsLoading(false)
      })
      .catch(() => {
        setBookingsLoading(false)
      })
  }
  const fetchFilteredBookings = async (page: number) => {
    setBookingsLoading(true)
    get(`booking/list?from=${dates.from}&to=${dates.to}&page=${page}&limit=10`)
      .then((result: any) => {
        if (result.status === 200) {
          setBookings(result)
        } else {
          notify('error', result.message)
        }
        setBookingsLoading(false)
      })
      .catch(() => {
        setBookingsLoading(false)
      })
  }
  useEffect(() => {
    fetchBookings(1)
  }, [])

  //Filter
  const [dates, setDates] = useState({
    from: '',
    to: '',
  })
  const handleChange = (e: any) => {
    const { name, value } = e.target
    setDates((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  function handleFilter() {
    if (!dates.from || !dates.to) {
      notify('error', `Dates can't be empty`)
    } else {
      setPaginationPage('date')
      fetchFilteredBookings(1)
    }
  }

  return (
    <div className='home'>
      <div className='home_tag flex justify-between items-center'>
        <h1></h1>
        <div className='flex items-center select-wrapper'>
          <div className='flex items-center'>
            <input
              type='date'
              name='from'
              value={dates.from}
              onChange={handleChange}
              max={new Date().toISOString().split('T')[0]}
            />
          </div>
          <div className='flex items-center'>
            <p>to</p>
            <input
              type='date'
              name='to'
              value={dates.to}
              onChange={handleChange}
              min={dates.from}
              max={new Date().toISOString().split('T')[0]}
            />
          </div>

          <button disabled={!dates.from || !dates.to} onClick={handleFilter}>
            filter
          </button>
        </div>
      </div>
      <div className='home_tables'>
        <div className='left'>
          {bookingsLoading ? (
            <FormLoader position='center' />
          ) : (
            <>
              {bookings.data.length > 0 ? (
                <>
                  <div className='mobile-table'>
                    {bookings.data.map((item: any, i: number) => (
                      <div
                        className='card'
                        onClick={() => navigate(`/user/booking/${item.id}`)}
                      >
                        <div className='flex justify-between row'>
                          <div className='count flex items-center justify-center'>
                            {i + 1}
                          </div>
                          <p> {new Date(item.createdAt).toLocaleString()}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Amount</h4>
                          <p>₦{item.charge.toLocaleString()}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Shipment type</h4>
                          <p>{item.shipment_type}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Delivery type</h4>
                          <p>{item.deliveryType}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Status</h4>
                          <span className={`chip ${item.booking_status.code}`}>
                            {item.booking_status.description}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <table className='desktop-table'>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Date/Time</th>
                        <th>Amount</th>
                        <th>Shipment Type</th>
                        <th>Delivery Type</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookings.data.map((item: any, i: number) => (
                        <tr key={item.id}>
                          <td>{i + 1}</td>
                          <td className='count'>
                            {new Date(item.createdAt).toLocaleString()}
                          </td>
                          <td className='count'>
                            NGN {item.charge.toLocaleString()}
                          </td>
                          <td className='count'>{item.shipment_type}</td>
                          <td className='count'>{item.deliveryType}</td>
                          <td>
                            <span
                              className={`chip ${item.booking_status.code}`}
                            >
                              {item.booking_status.description}
                            </span>
                          </td>
                          <td>
                            <span
                              onClick={() =>
                                navigate(`/user/booking/${item.id}`)
                              }
                              className='view'
                            >
                              view
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={bookings.pagination.page}
                    totalCount={bookings.pagination.totalPages}
                    pageSize={bookings.pagination.limit}
                    onPageChange={(page: number) => {
                      paginationPage === 'default'
                        ? fetchBookings(page)
                        : fetchFilteredBookings(page)
                    }}
                  />
                </>
              ) : (
                <div className='empty flex justify-center'>
                  <div className='empty-cover'>
                    <Icon icon='majesticons:book-line' />
                    <h1>No Bookings To Show</h1>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Bookings
