import { useParams } from 'react-router-dom'
import { useGlobalContext } from '../../utils/Context'
import Sidebar from './components/Sidebar'
import Topbar from './components/Topbar'
import './Dashboard.scss'
import Home from './dashPages/home/Home'
import Export from './dashPages/delivery/Export'
import Import from './dashPages/delivery/Import'
import Domestic from './dashPages/delivery/Domestic'
import Bookings from './dashPages/bookings/Bookings'
import Booking from './dashPages/bookings/Booking'
import Transactions from './dashPages/transactions/Transactions'
import DropOff from './dashPages/dropOff/DropOff'
import Trackings from './dashPages/tracking/Trackings'
import Tracking from './dashPages/tracking/Tracking'
import Settings from '../aggregatorDashboard/dashPages/settings/Settings'
import Drafts from '../../components/drafts/Drafts'
function UserDashboard() {
  const { page } = useParams()
  const { closeSideBar } = useGlobalContext()
  const activePage = () => {
    let active = ''
    switch (page) {
      case 'home':
        return <Home />
        break
      case 'export':
        return <Export />
      case 'import':
        return <Import />
      case 'domestic':
        return <Domestic />
      case 'bookings':
        return <Bookings />
      case 'booking':
        return <Booking />
      case 'transactions':
        return <Transactions />
      case 'drop-off':
        return <DropOff />
      case 'trackings':
        return <Trackings />
      case 'tracking':
        return <Tracking />
      case 'settings':
        return <Settings />
      case 'drafts':
        return <Drafts />
      default:
        break
    }
    return active
  }
  return (
    <div className='dashboard flex' onClick={closeSideBar}>
      <Sidebar page={page} />
      <div className='main'>
        <Topbar page={page} />
        <div className='main-body'>{activePage()}</div>
      </div>
    </div>
  )
}

export default UserDashboard
