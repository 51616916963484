const CountryPhone = [
  {
    name: 'Afghanistan',
    isoCode: 'AF',
    phoneCode: '+93',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Albania',
    isoCode: 'AL',
    phoneCode: '+355',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Algeria',
    isoCode: 'DZ',
    phoneCode: '+213',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Andorra',
    isoCode: 'AD',
    phoneCode: '+376',
    phoneLength: { min: 6, max: 9 },
  },
  {
    name: 'Angola',
    isoCode: 'AO',
    phoneCode: '+244',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Antigua and Barbuda',
    isoCode: 'AG',
    phoneCode: '+1-268',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Argentina',
    isoCode: 'AR',
    phoneCode: '+54',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Armenia',
    isoCode: 'AM',
    phoneCode: '+374',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Australia',
    isoCode: 'AU',
    phoneCode: '+61',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Austria',
    isoCode: 'AT',
    phoneCode: '+43',
    phoneLength: { min: 10, max: 13 },
  },
  {
    name: 'Azerbaijan',
    isoCode: 'AZ',
    phoneCode: '+994',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Bahamas',
    isoCode: 'BS',
    phoneCode: '+1-242',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Bahrain',
    isoCode: 'BH',
    phoneCode: '+973',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Bangladesh',
    isoCode: 'BD',
    phoneCode: '+880',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Barbados',
    isoCode: 'BB',
    phoneCode: '+1-246',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Belarus',
    isoCode: 'BY',
    phoneCode: '+375',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Belgium',
    isoCode: 'BE',
    phoneCode: '+32',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Belize',
    isoCode: 'BZ',
    phoneCode: '+501',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Benin',
    isoCode: 'BJ',
    phoneCode: '+229',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Bhutan',
    isoCode: 'BT',
    phoneCode: '+975',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Bolivia',
    isoCode: 'BO',
    phoneCode: '+591',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Bosnia and Herzegovina',
    isoCode: 'BA',
    phoneCode: '+387',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Botswana',
    isoCode: 'BW',
    phoneCode: '+267',
    phoneLength: { min: 7, max: 9 },
  },
  {
    name: 'Brazil',
    isoCode: 'BR',
    phoneCode: '+55',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Brunei',
    isoCode: 'BN',
    phoneCode: '+673',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Bulgaria',
    isoCode: 'BG',
    phoneCode: '+359',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Burkina Faso',
    isoCode: 'BF',
    phoneCode: '+226',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Burundi',
    isoCode: 'BI',
    phoneCode: '+257',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Cabo Verde',
    isoCode: 'CV',
    phoneCode: '+238',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Cambodia',
    isoCode: 'KH',
    phoneCode: '+855',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Cameroon',
    isoCode: 'CM',
    phoneCode: '+237',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Canada',
    isoCode: 'CA',
    phoneCode: '+1',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Central African Republic',
    isoCode: 'CF',
    phoneCode: '+236',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Chad',
    isoCode: 'TD',
    phoneCode: '+235',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Chile',
    isoCode: 'CL',
    phoneCode: '+56',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'China',
    isoCode: 'CN',
    phoneCode: '+86',
    phoneLength: { min: 11, max: 11 },
  },
  {
    name: 'Colombia',
    isoCode: 'CO',
    phoneCode: '+57',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Comoros',
    isoCode: 'KM',
    phoneCode: '+269',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Congo',
    isoCode: 'CG',
    phoneCode: '+242',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Costa Rica',
    isoCode: 'CR',
    phoneCode: '+506',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: "Côte d'Ivoire",
    isoCode: 'CI',
    phoneCode: '+225',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Croatia',
    isoCode: 'HR',
    phoneCode: '+385',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Cuba',
    isoCode: 'CU',
    phoneCode: '+53',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Cyprus',
    isoCode: 'CY',
    phoneCode: '+357',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Czech Republic',
    isoCode: 'CZ',
    phoneCode: '+420',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Democratic Republic of the Congo',
    isoCode: 'CD',
    phoneCode: '+243',
    phoneLength: { min: 9, max: 10 },
  },
  {
    name: 'Denmark',
    isoCode: 'DK',
    phoneCode: '+45',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Djibouti',
    isoCode: 'DJ',
    phoneCode: '+253',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Dominica',
    isoCode: 'DM',
    phoneCode: '+1-767',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Dominican Republic',
    isoCode: 'DO',
    phoneCode: '+1-809',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Ecuador',
    isoCode: 'EC',
    phoneCode: '+593',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Egypt',
    isoCode: 'EG',
    phoneCode: '+20',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'El Salvador',
    isoCode: 'SV',
    phoneCode: '+503',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Equatorial Guinea',
    isoCode: 'GQ',
    phoneCode: '+240',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Eritrea',
    isoCode: 'ER',
    phoneCode: '+291',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Estonia',
    isoCode: 'EE',
    phoneCode: '+372',
    phoneLength: { min: 7, max: 9 },
  },
  {
    name: 'Eswatini',
    isoCode: 'SZ',
    phoneCode: '+268',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Ethiopia',
    isoCode: 'ET',
    phoneCode: '+251',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'French Guiana',
    isoCode: 'GF',
    phoneCode: '+594',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Fiji',
    isoCode: 'FJ',
    phoneCode: '+679',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Finland',
    isoCode: 'FI',
    phoneCode: '+358',
    phoneLength: { min: 9, max: 11 },
  },
  {
    name: 'France',
    isoCode: 'FR',
    phoneCode: '+33',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Gabon',
    isoCode: 'GA',
    phoneCode: '+241',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Gambia',
    isoCode: 'GM',
    phoneCode: '+220',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Georgia',
    isoCode: 'GE',
    phoneCode: '+995',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Germany',
    isoCode: 'DE',
    phoneCode: '+49',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Ghana',
    isoCode: 'GH',
    phoneCode: '+233',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Greece',
    isoCode: 'GR',
    phoneCode: '+30',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Grenada',
    isoCode: 'GD',
    phoneCode: '+1-473',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Guatemala',
    isoCode: 'GT',
    phoneCode: '+502',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Guinea',
    isoCode: 'GN',
    phoneCode: '+224',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Guinea-Bissau',
    isoCode: 'GW',
    phoneCode: '+245',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Guyana',
    isoCode: 'GY',
    phoneCode: '+592',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Haiti',
    isoCode: 'HT',
    phoneCode: '+509',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Honduras',
    isoCode: 'HN',
    phoneCode: '+504',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Hungary',
    isoCode: 'HU',
    phoneCode: '+36',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Iceland',
    isoCode: 'IS',
    phoneCode: '+354',
    phoneLength: { min: 7, max: 9 },
  },
  {
    name: 'India',
    isoCode: 'IN',
    phoneCode: '+91',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Indonesia',
    isoCode: 'ID',
    phoneCode: '+62',
    phoneLength: { min: 9, max: 11 },
  },
  {
    name: 'Iran',
    isoCode: 'IR',
    phoneCode: '+98',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Iraq',
    isoCode: 'IQ',
    phoneCode: '+964',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Ireland',
    isoCode: 'IE',
    phoneCode: '+353',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Israel',
    isoCode: 'IL',
    phoneCode: '+972',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Italy',
    isoCode: 'IT',
    phoneCode: '+39',
    phoneLength: { min: 9, max: 11 },
  },
  {
    name: 'Jamaica',
    isoCode: 'JM',
    phoneCode: '+1-876',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Japan',
    isoCode: 'JP',
    phoneCode: '+81',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Jordan',
    isoCode: 'JO',
    phoneCode: '+962',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Kazakhstan',
    isoCode: 'KZ',
    phoneCode: '+7',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Kenya',
    isoCode: 'KE',
    phoneCode: '+254',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Kiribati',
    isoCode: 'KI',
    phoneCode: '+686',
    phoneLength: { min: 5, max: 9 },
  },
  {
    name: 'Korea, North',
    isoCode: 'KP',
    phoneCode: '+850',
    phoneLength: { min: 4, max: 11 },
  },
  {
    name: 'Korea, South',
    isoCode: 'KR',
    phoneCode: '+82',
    phoneLength: { min: 9, max: 11 },
  },
  {
    name: 'Kosovo',
    isoCode: 'XK',
    phoneCode: '+383',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Kuwait',
    isoCode: 'KW',
    phoneCode: '+965',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Kyrgyzstan',
    isoCode: 'KG',
    phoneCode: '+996',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Laos',
    isoCode: 'LA',
    phoneCode: '+856',
    phoneLength: { min: 8, max: 11 },
  },
  {
    name: 'Latvia',
    isoCode: 'LV',
    phoneCode: '+371',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Lebanon',
    isoCode: 'LB',
    phoneCode: '+961',
    phoneLength: { min: 7, max: 9 },
  },
  {
    name: 'Lesotho',
    isoCode: 'LS',
    phoneCode: '+266',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Liberia',
    isoCode: 'LR',
    phoneCode: '+231',
    phoneLength: { min: 7, max: 9 },
  },
  {
    name: 'Libya',
    isoCode: 'LY',
    phoneCode: '+218',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Liechtenstein',
    isoCode: 'LI',
    phoneCode: '+423',
    phoneLength: { min: 7, max: 9 },
  },
  {
    name: 'Lithuania',
    isoCode: 'LT',
    phoneCode: '+370',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Luxembourg',
    isoCode: 'LU',
    phoneCode: '+352',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Madagascar',
    isoCode: 'MG',
    phoneCode: '+261',
    phoneLength: { min: 9, max: 11 },
  },
  {
    name: 'Malawi',
    isoCode: 'MW',
    phoneCode: '+265',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Malaysia',
    isoCode: 'MY',
    phoneCode: '+60',
    phoneLength: { min: 9, max: 11 },
  },
  {
    name: 'Maldives',
    isoCode: 'MV',
    phoneCode: '+960',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Mali',
    isoCode: 'ML',
    phoneCode: '+223',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Malta',
    isoCode: 'MT',
    phoneCode: '+356',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Marshall Islands',
    isoCode: 'MH',
    phoneCode: '+692',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Mauritania',
    isoCode: 'MR',
    phoneCode: '+222',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Mauritius',
    isoCode: 'MU',
    phoneCode: '+230',
    phoneLength: { min: 7, max: 9 },
  },
  {
    name: 'Mexico',
    isoCode: 'MX',
    phoneCode: '+52',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Micronesia',
    isoCode: 'FM',
    phoneCode: '+691',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Moldova',
    isoCode: 'MD',
    phoneCode: '+373',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Monaco',
    isoCode: 'MC',
    phoneCode: '+377',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Mongolia',
    isoCode: 'MN',
    phoneCode: '+976',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Montenegro',
    isoCode: 'ME',
    phoneCode: '+382',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Morocco',
    isoCode: 'MA',
    phoneCode: '+212',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Mozambique',
    isoCode: 'MZ',
    phoneCode: '+258',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Myanmar',
    isoCode: 'MM',
    phoneCode: '+95',
    phoneLength: { min: 7, max: 9 },
  },
  {
    name: 'Namibia',
    isoCode: 'NA',
    phoneCode: '+264',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Nauru',
    isoCode: 'NR',
    phoneCode: '+674',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Nepal',
    isoCode: 'NP',
    phoneCode: '+977',
    phoneLength: { min: 8, max: 11 },
  },
  {
    name: 'Netherlands',
    isoCode: 'NL',
    phoneCode: '+31',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'New Zealand',
    isoCode: 'NZ',
    phoneCode: '+64',
    phoneLength: { min: 8, max: 11 },
  },
  {
    name: 'Nicaragua',
    isoCode: 'NI',
    phoneCode: '+505',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Niger',
    isoCode: 'NE',
    phoneCode: '+227',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Nigeria',
    isoCode: 'NG',
    phoneCode: '+234',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'North Macedonia',
    isoCode: 'MK',
    phoneCode: '+389',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Norway',
    isoCode: 'NO',
    phoneCode: '+47',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Oman',
    isoCode: 'OM',
    phoneCode: '+968',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Pakistan',
    isoCode: 'PK',
    phoneCode: '+92',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Palau',
    isoCode: 'PW',
    phoneCode: '+680',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Palestine',
    isoCode: 'PS',
    phoneCode: '+970',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Panama',
    isoCode: 'PA',
    phoneCode: '+507',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Papua New Guinea',
    isoCode: 'PG',
    phoneCode: '+675',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Paraguay',
    isoCode: 'PY',
    phoneCode: '+595',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Peru',
    isoCode: 'PE',
    phoneCode: '+51',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Philippines',
    isoCode: 'PH',
    phoneCode: '+63',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Poland',
    isoCode: 'PL',
    phoneCode: '+48',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Portugal',
    isoCode: 'PT',
    phoneCode: '+351',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Qatar',
    isoCode: 'QA',
    phoneCode: '+974',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Romania',
    isoCode: 'RO',
    phoneCode: '+40',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Russia',
    isoCode: 'RU',
    phoneCode: '+7',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Rwanda',
    isoCode: 'RW',
    phoneCode: '+250',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Saint Kitts and Nevis',
    isoCode: 'KN',
    phoneCode: '+1-869',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Saint Lucia',
    isoCode: 'LC',
    phoneCode: '+1-758',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Saint Vincent and the Grenadines',
    isoCode: 'VC',
    phoneCode: '+1-784',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Samoa',
    isoCode: 'WS',
    phoneCode: '+685',
    phoneLength: { min: 5, max: 8 },
  },
  {
    name: 'San Marino',
    isoCode: 'SM',
    phoneCode: '+378',
    phoneLength: { min: 9, max: 11 },
  },
  {
    name: 'Sao Tome and Principe',
    isoCode: 'ST',
    phoneCode: '+239',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Saudi Arabia',
    isoCode: 'SA',
    phoneCode: '+966',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Senegal',
    isoCode: 'SN',
    phoneCode: '+221',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Serbia',
    isoCode: 'RS',
    phoneCode: '+381',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Seychelles',
    isoCode: 'SC',
    phoneCode: '+248',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Sierra Leone',
    isoCode: 'SL',
    phoneCode: '+232',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Singapore',
    isoCode: 'SG',
    phoneCode: '+65',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Slovakia',
    isoCode: 'SK',
    phoneCode: '+421',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Slovenia',
    isoCode: 'SI',
    phoneCode: '+386',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Solomon Islands',
    isoCode: 'SB',
    phoneCode: '+677',
    phoneLength: { min: 5, max: 8 },
  },
  {
    name: 'Somalia',
    isoCode: 'SO',
    phoneCode: '+252',
    phoneLength: { min: 7, max: 9 },
  },
  {
    name: 'South Africa',
    isoCode: 'ZA',
    phoneCode: '+27',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'South Sudan',
    isoCode: 'SS',
    phoneCode: '+211',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Spain',
    isoCode: 'ES',
    phoneCode: '+34',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Sri Lanka',
    isoCode: 'LK',
    phoneCode: '+94',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Sudan',
    isoCode: 'SD',
    phoneCode: '+249',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Suriname',
    isoCode: 'SR',
    phoneCode: '+597',
    phoneLength: { min: 6, max: 8 },
  },
  {
    name: 'Sweden',
    isoCode: 'SE',
    phoneCode: '+46',
    phoneLength: { min: 7, max: 13 },
  },
  {
    name: 'Switzerland',
    isoCode: 'CH',
    phoneCode: '+41',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Syria',
    isoCode: 'SY',
    phoneCode: '+963',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Taiwan',
    isoCode: 'TW',
    phoneCode: '+886',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Tajikistan',
    isoCode: 'TJ',
    phoneCode: '+992',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Tanzania',
    isoCode: 'TZ',
    phoneCode: '+255',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Thailand',
    isoCode: 'TH',
    phoneCode: '+66',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Timor-Leste',
    isoCode: 'TL',
    phoneCode: '+670',
    phoneLength: { min: 7, max: 9 },
  },
  {
    name: 'Togo',
    isoCode: 'TG',
    phoneCode: '+228',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Tonga',
    isoCode: 'TO',
    phoneCode: '+676',
    phoneLength: { min: 5, max: 8 },
  },
  {
    name: 'Trinidad and Tobago',
    isoCode: 'TT',
    phoneCode: '+1-868',
    phoneLength: { min: 7, max: 8 },
  },
  {
    name: 'Tunisia',
    isoCode: 'TN',
    phoneCode: '+216',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Turkey',
    isoCode: 'TR',
    phoneCode: '+90',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Turkmenistan',
    isoCode: 'TM',
    phoneCode: '+993',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Tuvalu',
    isoCode: 'TV',
    phoneCode: '+688',
    phoneLength: { min: 5, max: 5 },
  },
  {
    name: 'Uganda',
    isoCode: 'UG',
    phoneCode: '+256',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Ukraine',
    isoCode: 'UA',
    phoneCode: '+380',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'United Arab Emirates',
    isoCode: 'AE',
    phoneCode: '+971',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'United Kingdom',
    isoCode: 'GB',
    phoneCode: '+44',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'United States',
    isoCode: 'US',
    phoneCode: '+1',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Uruguay',
    isoCode: 'UY',
    phoneCode: '+598',
    phoneLength: { min: 8, max: 9 },
  },
  {
    name: 'Uzbekistan',
    isoCode: 'UZ',
    phoneCode: '+998',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Vanuatu',
    isoCode: 'VU',
    phoneCode: '+678',
    phoneLength: { min: 5, max: 8 },
  },
  {
    name: 'Vatican City',
    isoCode: 'VA',
    phoneCode: '+379',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Venezuela',
    isoCode: 'VE',
    phoneCode: '+58',
    phoneLength: { min: 10, max: 11 },
  },
  {
    name: 'Vietnam',
    isoCode: 'VN',
    phoneCode: '+84',
    phoneLength: { min: 9, max: 11 },
  },
  {
    name: 'Yemen',
    isoCode: 'YE',
    phoneCode: '+967',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Zambia',
    isoCode: 'ZM',
    phoneCode: '+260',
    phoneLength: { min: 9, max: 9 },
  },
  {
    name: 'Zimbabwe',
    isoCode: 'ZW',
    phoneCode: '+263',
    phoneLength: { min: 9, max: 9 },
  },
]

export default CountryPhone
