import { Icon } from '@iconify/react'
import { useGlobalContext } from '../../../utils/Context'

function Topbar({ page }: any) {
  const activeTab = () => {
    let active = ''

    switch (page) {
      case 'home':
        active = 'Dashboard'
        break
      case 'export':
        active = 'Export'
        break
      case 'import':
        active = 'Import'
        break
      case 'domestic':
        active = 'Domestic'
        break
      case 'bookings':
        active = 'Bookings'
        break
      case 'transactions':
        active = 'Transactions'
        break
      case 'drop-off':
        active = 'Drop-off Offices'
        break
      case 'trackings':
        active = 'Package Tracking'
        break
      case 'settings':
        active = 'Settings'
        break
      case 'drafts':
        active = 'Drafts'
        break

      default:
        break
    }
    return active
  }
  const { profile, handleSidebarToggle } = useGlobalContext()

  return (
    <div className='topbar flex items-center'>
      <div className=' row flex items-center justify-between'>
        <div className='one flex items-center'>
          <div className='icon'>
            <Icon icon={'fa6-solid:bars'} onClick={handleSidebarToggle} />
          </div>
          <h1>{activeTab()}</h1>
        </div>
        <div className='two'>
          <h2>
            {profile
              ? `${profile.firstname} ${profile.lastname}`
              : 'loading...'}
          </h2>
          <p>User</p>
        </div>
      </div>
    </div>
  )
}

export default Topbar
