import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from './components/Navbar'
import './Landing.scss'
import Pana from '../../assets/images/pana.png'
import { Icon } from '@iconify/react'
import AboutImg from '../../assets/images/rafiki.png'
import Amico from '../../assets/images/amico.png'
import Amico1 from '../../assets/images/amico1.png'
import Cuate from '../../assets/images/cuate.png'
import Sprial1 from '../../assets/images/Vector 3.png'
import Sprial2 from '../../assets/images/Vector 2.png'
import WhyData from './components/WhyData'
import Slider from 'react-slick'
import ReviewsData from '../../assets/data/ReviewsData'
import FaqsData from '../../assets/data/FaqsData'
import Logo from '../../assets/images/logo-white.png'
import { Link } from 'react-scroll'

function Landing() {
  const navigate = useNavigate()
  const [selectedQuestion, setSelectedQuestion] = useState(0)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    className: 'slides',
    nextArrow: <Icon icon={'icon-park-solid:right-c'} />,
    prevArrow: <Icon icon={'icon-park-solid:left-c'} />,
    autoplay: true,

    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className='landing-page'>
      <Navbar />
      <div className='hero home'>
        <div className='hero-wrapper flex justify-between'>
          <div className='left'>
            <div className='left-wrap'>
              <h1>Where Every Delivery Goes Beyond Expectations</h1>
              <p>
                Our one-stop-shop for best-in-class delivery locally and
                internationally
              </p>
            </div>
            <div className='buttons'>
              <button onClick={() => navigate('/create-account')}>
                Get Started
              </button>
              <button onClick={() => navigate('/users/login')}>Log In</button>
            </div>
          </div>
          <div className='right flex'>
            <img src={Pana} alt='Man on bike' />
          </div>
        </div>
      </div>
      <div className='delivery'>
        <div className='white-bg'></div>
        <div className='blue-bg'>
          <div className='cover'>
            <div className='cover-top'>
              <p>TIMELY DELIVERY</p>
              <h2>
                Safe, Fast and Secure. Wherever you need it, we'll get it there.
              </h2>
            </div>
            <div className='flex justify-between'>
              <div className='card'>
                <Icon icon='solar:point-on-map-bold-duotone' className='icon' />
                <h3>Intra-State Delivery</h3>
                <p>
                  We offer fast and reliable intra-state delivery, connecting
                  every corner of your city with ease. Your goods' safety is our
                  top priority, so we make sure your deliveries are faster and
                  stress-free.
                </p>
              </div>
              <div className='card'>
                <Icon
                  icon='solar:map-arrow-square-bold-duotone'
                  className='icon'
                />
                <h3>Inter-State Delivery</h3>
                <p>
                  Your goods are safe from risk with our insurance policy as
                  they travel from one state to another. We ensure clear
                  communication about your delivery by allowing you to track
                  your shipment and receive updates, giving you peace of mind.
                </p>
              </div>
              <div className='card'>
                <Icon
                  icon='heroicons:globe-europe-africa-solid'
                  className='icon'
                />
                <h3>Worldwide Delivery</h3>
                <p>
                  We help break down international barriers by ensuring your
                  goods travel quickly and safely around the world, no matter
                  the distance. Our smart software finds the best routes and
                  schedules, cutting travel costs and saving you money.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='about-us'>
        <div className='about-us-wrapper flex justify-between wrap-reverse items-center'>
          <div className='left'>
            <img src={AboutImg} alt='About Us' />
          </div>
          <div className='right'>
            <div className='head'>
              <p className='top'>Who we are?</p>
              <h1>
                ABOUT <span>QC - EXPRESS</span>
              </h1>
              <p className='content'>
                After years in the logistic industry, we've evolved into making
                delivery as simple as it should, exceeding your expectations.
                Whether you're shipping locally, across states, or around the
                world, we've got you covered. Our delivery solutions are
                tailored to fit your needs and ensure your goods reach their
                destination safely and on time. Our software technology tracks
                every step of the delivery process, giving you real-time
                updates. With a focus on efficiency, reliability, and customer
                satisfaction, we treat every shipment with the utmost care and
                concern. With our 300+ well-trained employees and a friendly
                team always ready to help, we ensure a hassle-free delivery
                experience. From small packages to large freight, we offer
                flexible solutions for businesses and individuals alike. No
                matter the challenge, we take pride in overcoming it to meet
                your logistics needs. Count on us to be your go-to partner,
                ensuring your goods are where they need to be, when they need to
                be there.
              </p>
            </div>
            <div className='cards'>
              <div className='flex'>
                <div className='icon'>
                  <Icon icon='lineicons:certificate-badge-1' />
                </div>
                <div className='texts'>
                  <h2>Efficiency</h2>
                  <p>
                    We focus on delivering your shipments on time, accurately,
                    and at the best cost without compromising quality. Our
                    efficient supply chain ensures fast deliveries, smooth
                    warehouse operations, and well-managed inventory—all to keep
                    you satisfied.
                  </p>
                </div>
              </div>
              <div className='flex'>
                <div className='icon'>
                  <Icon icon='stash:shield-duotone' />
                </div>
                <div className='texts'>
                  <h2>Reliability</h2>
                  <p>
                    We make you enjoy high service standards such as real-time
                    tracking, clear communication, and swift problem resolution
                    to keep you informed and confident. By combining reliability
                    and trust, we guarantee smooth and secure shipping every
                    time.
                  </p>
                </div>
              </div>
              <div className='flex'>
                <div className='icon'>
                  <Icon icon='clarity:group-solid-badged' />
                </div>
                <div className='texts'>
                  <h2>Customer Satisfaction</h2>
                  <p>
                    Our mission, vision, and values are built with you in mind.
                    Your complaints are addressed quickly, you enjoy fair
                    pricing with a clear cost breakdown, and we ensure our
                    employees always serve you in a friendly and respectful
                    manner.
                  </p>
                </div>
              </div>
            </div>
            <button onClick={() => navigate('/create-account')}>
              Get Started
            </button>
          </div>
        </div>
      </div>
      <div className='efficient'>
        <div className='efficient-wrap'>
          <div className='head'>
            <p className='top'>Our Efficient delivery system</p>
            <h2 className='uppercase'>
              How We Provide Efficiency No Matter Your Location.
            </h2>
            <div className='content'>
              Connecting every corner of the city with ease. Your local delivery
              solution for timely, hassle-free shipments.
            </div>
          </div>
          <div className='flex items-center flex-wrap'>
            <div className='left'>
              <img src={Amico} alt='Amico' />
            </div>
            <div className='right'>
              <h2 className='uppercase'>
                Online Tracking and On-time Deliveries
              </h2>
              <p>
                Stay in control with our smart software! Our real-time online
                tracking keeps you updated instantly on your shipment’s status,
                so you always know where it is. You can easily monitor every
                step of the delivery process, from start to finish. We’re all
                about efficiency, making sure your deliveries arrive on time,
                every time.
              </p>
              <button onClick={() => navigate('/create-account')}>
                Get Started
              </button>
            </div>
          </div>
          <div className='spiral'>
            <img src={Sprial1} alt='spiral' />
          </div>
          <div className='flex items-center wrap-reverse'>
            <div className='right'>
              <h2 className='uppercase'>
                flexible rates and convenient transit times
              </h2>
              <p>
                We offer flexible rates that fit your budget, so you get the
                best value for your shipment. Plus, our convenient transit times
                let you choose the delivery speed that works best for you,
                without sacrificing reliability. Whether it’s urgent or routine,
                we’ve got solutions that match your schedule and financial
                needs.
              </p>
              <button onClick={() => navigate('/create-account')}>
                Get Started
              </button>
            </div>
            <div className='left'>
              <img src={Amico1} alt='Amico' />
            </div>
          </div>
          <div className='spiral dif'>
            <img src={Sprial2} alt='spiral' />
          </div>
          <div className='flex items-center flex-wrap'>
            <div className='left'>
              <img src={Cuate} alt='Amico' />
            </div>
            <div className='right'>
              <h2>QUICK ORDER PLACEMENT WITH INSTANT CONFIRMATION</h2>
              <p>
                Ordering is super easy with our fast and seamless process. As
                soon as you place your order, you’ll get an instant electronic
                confirmation—giving you peace of mind. Our user-friendly system
                ensures your shipment is processed quickly and accurately from
                start to finish.
              </p>
              <button onClick={() => navigate('/create-account')}>
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='why-us'>
        <div className='wrapper'>
          <div className='head'>
            <h2 className=' uppercase'>Why you should chooose us.</h2>
            <p>
              Here’s why we’re the right choice for you! Our team uses the
              latest software, platforms, and tools to make sure we deliver
              top-quality results that fit your unique needs.
            </p>
          </div>
          <div className='flex flex-wrap justify-between'>
            {WhyData.map((data, index) => (
              <div className='card' key={index}>
                <div className='card-wrap'>
                  <div className='icon'>
                    <Icon icon={data.icon} />
                  </div>
                  <h3>{data.tag}</h3>
                  <p>{data.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='reviews'>
        <div className='wrapper'>
          <div className='top'>
            <h1>What our customer says about us</h1>
            <p>
              Our mission is to help businesses succeed and nothing makes us
              prouder than seeing our clients achieve their goals.
            </p>
          </div>
          <div className='review-list'>
            <Slider {...settings}>
              {ReviewsData.map((data, index) => (
                <div key={index}>
                  <div className='card'>
                    <div className='flex'>
                      <div className='left'>
                        <img src={data.image} alt='' />
                      </div>
                      <div className='right'>
                        <p>{data.review}</p>
                        <h3>{data.name}</h3>
                        <h3>{data.company}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className='faqs'>
        <div className='wrapper'>
          <div className='top'>
            <h1>frequently asked questions(FAQs)</h1>
            <p>
              Find answers to common inquiries in our Frequently Asked Questions
              (FAQ) section."
            </p>
          </div>
          <div className='faq-list'>
            {FaqsData.map((data, index) => (
              <div className='faq' key={index}>
                <div
                  className='question flex justify-between'
                  onClick={() => setSelectedQuestion(index)}
                >
                  <h3>{data.question}</h3>
                  <div>
                    {selectedQuestion === index ? (
                      <Icon icon='akar-icons:chevron-up' />
                    ) : (
                      <Icon icon='akar-icons:chevron-down' />
                    )}
                  </div>
                </div>
                {selectedQuestion === index && (
                  <div className='answer'>
                    <p>{data.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='footer'>
        <div className='wrapper'>
          <div className='flex justify-between flex-wrap'>
            <div className='card logo'>
              <img src={Logo} alt='' />
              <p>
                Your trusted partner in getting goods where they need to be,
                fast and safe.
              </p>
            </div>
            <div className='card'>
              <h3>Quick Links</h3>
              <ul>
                {/* @ts-ignore */}
                <Link to='home' smooth={true} duration={1000}>
                  <li>Home</li>
                </Link>
                {/* @ts-ignore */}
                <Link to='about-us' smooth={true} duration={1000}>
                  <li>About Us</li>
                </Link>
                {/* @ts-ignore */}
                <Link to='why-us' smooth={true} duration={1000}>
                  <li>Why Us</li>
                </Link>
                {/* @ts-ignore */}
                <Link to='reviews' smooth={true} duration={1000}>
                  <li>Testimonials </li>
                </Link>
                {/* @ts-ignore */}
                <Link to='faqs' smooth={true} duration={1000}>
                  <li>FAQs</li>
                </Link>
                <a href='https://developers.qcexpress.co/'>
                  <li>Developers</li>
                </a>
                <li>Terms Of Services</li>
                <li>Privacy</li>
              </ul>
            </div>
            <div className='card'>
              <h3>Contact Us</h3>
              <div className='flex'>
                <div>
                  <div className='icon'>
                    <Icon icon='mdi:location' />
                  </div>
                </div>
                <div className='text'>
                  <p>Location:</p>
                  <p>
                    14A Olatunji Close, Off Continental Way, Ikosi GRA IV,
                    Magodo, Lagos-Nigeria.
                  </p>
                </div>
              </div>
              <div className='flex'>
                <div>
                  <div className='icon'>
                    <Icon icon='ic:baseline-phone' />
                  </div>
                </div>
                <div className='text'>
                  <p>Phone Number:</p>
                  <p>
                    +234 912 049 9852–7, +234 802 0549 655, +234 802 290 2446
                  </p>
                </div>
              </div>
              <div className='flex'>
                <div>
                  <div className='icon'>
                    <Icon icon='bxs:envelope' />
                  </div>
                </div>
                <div className='text'>
                  <p>Email:</p>
                  <p>customercare@quartzclassic.com</p>
                </div>
              </div>
            </div>
            <div className='card'>
              <h3>Socials</h3>
              <div className='socials'>
                <Icon icon='akar-icons:facebook-fill' />
                <Icon icon='akar-icons:instagram-fill' />
                <Icon icon='akar-icons:twitter-fill' />
                <Icon icon='akar-icons:linkedin-fill' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
