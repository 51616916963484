import './Modal.scss'

function Modal({ children, position }: any) {
  return (
    <div
      className={`main-modal flex ${
        position === 'center'
          ? 'justify-center items-center'
          : 'justify-end left'
      }`}
    >
      <div className='modal-wrapper'>{children}</div>
    </div>
  )
}

export default Modal
