import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import Login from './pages/auth/AggregatorLogin'
import { ToastContainer } from 'react-toastify'
import Dashboard from './pages/aggregatorDashboard/Dashboard'
import ProtectedRoute from './utils/ProtectedRoute'
import Landing from './pages/landing/Landing'
import UserLogin from './pages/auth/UserLogin'
import UserDashboard from './pages/userDashboard/Dashboard'
import ForgotPassword from './pages/auth/ForgotPassword'
import ResetPassword from './pages/auth/ResetPassword'
import CreateAccount from './pages/auth/CreateAccount'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ScrollToTop from './utils/ScrollToTop'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/create-account' element={<CreateAccount />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset' element={<ResetPassword />} />
        <Route path='/users/login' element={<UserLogin />} />
        <Route path='/aggregators/login' element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path='/aggregator/:page' element={<Dashboard />} />
          <Route path='/aggregator/:page/:id' element={<Dashboard />} />
          {/* //User Dashboard */}
          <Route path='/user/:page' element={<UserDashboard />} />
          <Route path='/user/:page/:id' element={<UserDashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
