import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function Tracking() {
  const [trackingId, setTrackingId] = useState('')
  const navigate = useNavigate()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (trackingId) {
      navigate(`/user/tracking/${trackingId}`)
    }
  }

  return (
    <div className='flex items-center justify-center drop-off'>
      <div className='wrapper tracking'>
        <h2>Track Package</h2>
        <p>Provide a tracking number to continue.</p>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type='text'
              placeholder='tracking id'
              value={trackingId}
              onChange={(e) => setTrackingId(e.target.value)}
            />
          </div>
          <button type='submit'>Track</button>
        </form>
      </div>
    </div>
  )
}

export default Tracking
