import { useState } from 'react'
import Select from 'react-select'
import { useGlobalContext } from '../../../../../utils/Context'
import { get } from '../../../../../utils/Api'
import FormLoader from '../../../../../components/loader/FormLoader'

export const SpecifyItem = ({
  handleSpecifiedItemSubmit,
  specifiedItems,
  hide,
  country,
  loading,
  delivery,
}: any) => {
  const { notify } = useGlobalContext()
  const [items, setItems] = useState<any>(specifiedItems || [])
  const [errors, setErrors] = useState<any>([])

  const handleInputChange = (index: number, field: string, value: any) => {
    const updatedItems = [...items]
    updatedItems[index][field] = value
    setItems(updatedItems)
  }

  const validate = () => {
    const newErrors = items.map((item: any) => ({
      description: !item.description ? 'This field is required' : '',
      itemQuantity: !item.itemQuantity
        ? 'This field is required'
        : isNaN(item.itemQuantity)
        ? 'Must be a number'
        : '',
      grossValue: !item.grossValue
        ? 'This field is required'
        : isNaN(item.grossValue)
        ? 'Must be a number'
        : '',
      itemValue: !item.itemValue
        ? 'This field is required'
        : isNaN(item.itemValue)
        ? 'Must be a number'
        : '',
      commodityCodes: hide
        ? ''
        : !item.commodityCodes
        ? 'This field is required'
        : isNaN(item.commodityCodes.value)
        ? 'Must be a number'
        : '',
    }))

    setErrors(newErrors)

    return newErrors.every((item: any) =>
      Object.values(item).every((error) => !error)
    )
  }

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        description: '',
        itemQuantity: '',
        grossValue: '',
        itemValue: '',
        commodityCodes: '',
      },
    ])
    setItemDescription('')
    setItemMaterial('')
    setItemUsage('')
    setOptions([])
  }

  const handleRemoveItem = (index: number) => {
    const updatedItems = items.filter((_: any, i: number) => i !== index)
    setItems(updatedItems)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (parseFloat(delivery?.weight) < calculateTotalWeight()) {
      notify(
        'error',
        'Total weight of items must be the same as the  total weight of package or less'
      )
      return
    }
    if (parseFloat(delivery?.value) < calculateTotalValue()) {
      notify(
        'error',
        'Total value of items must be the same total value of package or less'
      )
      return
    }
    if (validate()) {
      handleSpecifiedItemSubmit(items)
    }
  }

  /// Fetch HsCode
  const [codeLoading, setCodeLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [itemDescription, setItemDescription] = useState('')
  const [itemMaterial, setItemMaterial] = useState('')
  const [itemUsage, setItemUsage] = useState('')

  const handleInputBlur = async () => {
    if (!itemDescription) {
      return notify('error', 'Please fill what the item is')
    }
    setCodeLoading(true)

    get(
      `/booking/fetch-hs-code?countryCode=${country}&itemDescription=${itemDescription}&itemMadeOf=${itemMaterial}&itemUsage=${itemUsage}`
    )
      .then((result: any) => {
        if (result.status === 200) {
          const transformedArray = result.data.map((item: any) => {
            // Remove <span> tags
            let cleanLabel = item.description
            // Remove <em> and its content

            return {
              value: item.code, // Code becomes the value
              label: cleanLabel, // Cleaned and trimmed label
            }
          })
          setOptions(transformedArray)
        } else {
          notify('error', result.message)
        }
        setCodeLoading(false)
      })
      .catch(() => {
        setCodeLoading(false)
      })

    // setTransactions(res)
  }

  const calculateTotalWeight = () => {
    return items.reduce((total: number, item: any) => {
      return total + (parseFloat(item.grossValue) || 0)
    }, 0)
  }

  const calculateTotalValue = () => {
    return items.reduce((total: number, item: any) => {
      return (
        total +
        (parseFloat(item.itemQuantity) * parseFloat(item.itemValue) || 0)
      )
    }, 0)
  }

  return (
    <div className='form'>
      <form onSubmit={handleSubmit}>
        {items.map((item: any, index: number) => (
          <div key={index} className='itemArray'>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {items.length > 1 && (
                <p
                  onClick={() => handleRemoveItem(index)}
                  style={{ cursor: 'pointer', color: 'red' }}
                >
                  x
                </p>
              )}
            </div>
            <div className='input-card'>
              <p>Item description</p>
              <input
                id={`description-${index}`}
                type='text'
                value={item.description}
                onChange={(e) =>
                  handleInputChange(index, 'description', e.target.value)
                }
              />
              {errors[index]?.description && (
                <div className='field-error' style={{ color: 'red' }}>
                  {errors[index].description}
                </div>
              )}
            </div>
            <div className='input-card'>
              <p>Item quantity</p>
              <input
                id={`itemQuantity-${index}`}
                type='number'
                value={item.itemQuantity}
                onChange={(e) =>
                  handleInputChange(index, 'itemQuantity', e.target.value)
                }
              />
              {errors[index]?.itemQuantity && (
                <div className='field-error' style={{ color: 'red' }}>
                  {errors[index].itemQuantity}
                </div>
              )}
            </div>
            {!hide && (
              <>
                <div className='input-card hs-code'>
                  <p>Commodity Code</p>
                  <div className='up-guy'>
                    <div className='flex'>
                      <input
                        type='text'
                        placeholder='what is the item?'
                        onChange={(e) => setItemDescription(e.target.value)}
                      />
                      <input
                        type='text'
                        placeholder='what is it made of?'
                        onChange={(e) => setItemMaterial(e.target.value)}
                      />
                      <input
                        type='text'
                        placeholder='How will it be used?'
                        onChange={(e) => setItemUsage(e.target.value)}
                      />
                      <button
                        onClick={(e: any) => {
                          e.preventDefault()
                          handleInputBlur()
                          // Add your search logic here
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className='input-card'>
                  <p>Select Code</p>
                  <Select
                    options={options}
                    className='dif'
                    value={item.commodityCodes}
                    onChange={(selectedOption) =>
                      handleInputChange(index, 'commodityCodes', selectedOption)
                    }
                    isLoading={codeLoading}
                  />
                  {errors[index]?.commodityCodes && (
                    <div className='field-error' style={{ color: 'red' }}>
                      {errors[index].commodityCodes}
                    </div>
                  )}
                  <span style={{ fontSize: '10px', fontStyle: 'italic' }}>
                    If Category List is empty, try another description.
                  </span>
                </div>
              </>
            )}
            <div className='input-card'>
              <p>Weight</p>
              <input
                id={`grossValue-${index}`}
                type='number'
                value={item.grossValue}
                onChange={(e) =>
                  handleInputChange(index, 'grossValue', e.target.value)
                }
              />
              {errors[index]?.grossValue && (
                <div className='field-error' style={{ color: 'red' }}>
                  {errors[index].grossValue}
                </div>
              )}
            </div>
            <div className='input-card'>
              <p>Item price</p>
              <input
                id={`itemValue-${index}`}
                type='number'
                value={item.itemValue}
                onChange={(e) =>
                  handleInputChange(index, 'itemValue', e.target.value)
                }
              />
              {errors[index]?.itemValue && (
                <div className='field-error' style={{ color: 'red' }}>
                  {errors[index].itemValue}
                </div>
              )}
            </div>
          </div>
        ))}

        <div className='input-card'>
          <button onClick={handleAddItem} className='more-items'>
            Specify more item in package
          </button>
        </div>

        <div className='info-text'>
          <div className='flex justify-between'>
            <p>Total weight: {delivery?.weight} kg</p>
            <p>Package weight: {calculateTotalWeight()} kg</p>
          </div>
          <div className='flex justify-between'>
            <p>
              Total value: NGN {parseFloat(delivery?.value).toLocaleString()}
            </p>
            <p>Package value: NGN {calculateTotalValue().toLocaleString()} </p>
          </div>
        </div>
        <div className='btnsfd'>
          {loading ? (
            <FormLoader position='center' />
          ) : (
            <button className='where-address-button' type='submit'>
              Continue
            </button>
          )}
        </div>
      </form>
    </div>
  )
}
