import axios from 'axios'
import { Country, State } from 'country-state-city'

export const Newcountries = Country.getAllCountries()

export const states = (code: string) => {
  let s = State.getStatesOfCountry(code)
  return s
}

export const getLocalCities = async (state: string) => {
  try {
    let res = await axios({
      method: 'GET',
      url: `https://locus.fkkas.com/api/regions/${state}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (res) {
      return res?.data?.data
    }
  } catch (error: any) {
    return { er: error.response.data }
  }
}
