import './Loader.scss'

function FormLoader({ position }: any) {
  return (
    <div className={`f-loader ${position}`}>
      <div className='container'>
        <div className='half'></div>
        <div className='half'></div>
      </div>
    </div>
  )
}

export default FormLoader
