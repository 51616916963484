import { useState, useContext, createContext, useEffect } from 'react'
const AppContext = createContext<any | undefined>(undefined)
import { toast } from 'react-toastify'
import { get } from './Api'
// import { get } from './Api'

const AppProvider = ({ children }: any) => {
  const adminToken: any = sessionStorage.getItem('AdminToken')

  const notify = (type: string, message: string) => {
    if (type === 'info') {
      toast.info(message)
    }
    if (type === 'success') {
      toast.success(message)
    }
    if (type === 'warn') {
      toast.warn(message)
    }
    if (type === 'error') {
      toast.error(message)
    }
  }
  const [toggleSidebar, setToggleSidebar] = useState(false)
  const handleSidebarToggle = () => {
    setToggleSidebar(!toggleSidebar)
  }
  const closeSideBar = () => {
    if (toggleSidebar) {
      setToggleSidebar(!toggleSidebar)
    }
  }
  const calculatePaymentAmount = (input: number | string): number => {
    let amount = typeof input === 'string' ? parseFloat(input) : input
    let calculatedValue = amount * 0.015 + 100
    return parseFloat(Math.min(calculatedValue, 2000).toFixed(2))
  }
  // User Profile
  const [profileLoading, setProfileLoading] = useState(true)
  const profile: any = sessionStorage.getItem('user')
    ? JSON.parse(sessionStorage.getItem('user') || '')
    : {}
  function fetchUserProfile() {
    setProfileLoading(true)
    get('user/profile')
      .then((result: any) => {
        if (result.status === 200) {
          sessionStorage.setItem('user', JSON.stringify(result.data))
          setProfileLoading(false)
        } else if (result.status === 403) {
          sessionStorage.clear()
          window.location.replace('/')
          setProfileLoading(false)
        } else {
          notify('error', result.message)
        }
      })
      .catch(() => {
        setProfileLoading(false)
      })
  }

  useEffect(() => {
    if (
      profile.role === 'user' &&
      location.pathname.startsWith('/aggregator')
    ) {
      sessionStorage.clear()
      window.location.replace('/')
    } else if (
      (profile.role === 'aggregator' || profile.role === 'super-aggregator') &&
      location.pathname.startsWith('/user')
    ) {
      sessionStorage.clear()
      window.location.replace('/')
    }
  }, [profile])

  return (
    <AppContext.Provider
      value={{
        notify,
        adminToken,
        handleSidebarToggle,
        toggleSidebar,
        closeSideBar,
        profile,
        profileLoading,
        fetchUserProfile,
        calculatePaymentAmount,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
