import { useState } from 'react'
import Modal from '../../../../components/modal/Modal'
import { get, post } from '../../../../utils/Api'
import { useGlobalContext } from '../../../../utils/Context'
import Paystack from '@paystack/inline-js'
import FormLoader from '../../../../components/loader/FormLoader'
const paystackPublic = (import.meta as any).env.VITE_PAYSTACK_PUBLIC_KEY

interface PaymentModalProps {
  amount: number
  usage: string
  close: any
}

function PaymentModal({ amount, usage, close }: PaymentModalProps) {
  const { notify, fetchUserProfile, profile } = useGlobalContext()
  const [paymentLoading, setPaymentLoading] = useState(false)
  const popup = new Paystack()

  const initiatePayment = async () => {
    const data = {
      amount: parseFloat(profile.debt).toFixed(2),
      usage: usage,
    }

    setPaymentLoading(true)
    post('payment/initialize', data, null)
      .then((result: any) => {
        if (result.status === 200) {
          popup.newTransaction({
            key: paystackPublic, // Replace with your public Paystack key
            reference: result.data.reference,
            amount: parseFloat((amount * 100).toFixed(2)),
            email: profile.email,
            onSuccess: () => {
              // Redirect to the backend to verify payment
              verifyPayment(result.data.reference)
            },
            onCancel: () => {
              verifyPayment(result.data.reference)
            },
          })
        } else {
          notify('error', result.er.message)
        }
        setPaymentLoading(false)
      })
      .catch(() => {
        setPaymentLoading(false)
      })
  }
  // setPaymentLoading(false)
  const verifyPayment = async (id: string) => {
    setPaymentLoading(true)
    get(`payment/verify/${id}`)
      .then((result: any) => {
        if (result.status === 200) {
          setPaymentLoading(false)
          if (result.message === 'Payment failed') {
            notify('error', 'Payment failed')
          } else {
            notify('success', 'Payment Successful')
          }
          fetchUserProfile()
          close()
        } else {
          notify('error', result.message)
        }
      })
      .catch(() => {
        setPaymentLoading(false)
      })
  }
  return (
    <Modal position='center'>
      <div className='payment-modal'>
        <h1>Payment Portal</h1>
        <p>Note: You are about to initiate a transaction</p>
        <h3 className='flex justify-between'>
          <span>Amount: </span>₦
          {amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </h3>
        {paymentLoading ? (
          <FormLoader position='center' />
        ) : (
          <div className='buttons flex justify-center'>
            <button onClick={close}>Cancel</button>
            <button className='proceed' onClick={initiatePayment}>
              Proceed
            </button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default PaymentModal
