const DHLOffices = [
  { name: 'DHL Ibadan', city: 'Ibadan', address: '47 Ring Road, Ibadan' },
  {
    name: 'DHL Kano',
    city: 'Kano',
    address: '139, Murtala Mohammed Way, Kano',
  },
  {
    name: 'DHL Katsina',
    city: 'Katsina',
    address: '2, IBB Way, Kofar Kaura, Katsina',
  },
  { name: 'DHL Jos', city: 'Jos', address: '23, Rwangpam Street, Jos' },
  {
    name: 'DHL Sokoto',
    city: 'Sokoto',
    address: '16, Abdullahi Fodio, Sokoto',
  },
  {
    name: 'DHL Bauchi',
    city: 'Bauchi',
    address: '6, Yandoka Road, beside Total Filling Station, Bauchi',
  },
  {
    name: 'DHL Gombe',
    city: 'Gombe',
    address: 'Jakadafare Street, Opp PDP Office by Govt House Junction, Gombe',
  },
  {
    name: 'DHL Yola',
    city: 'Yola',
    address: '83, Mohammed Mustapha Way, Nasarawo Jimeta, Yola',
  },
  {
    name: 'DHL Maiduguri',
    city: 'Maiduguri',
    address: '75, Sir Kashim Ibrahim Way, Maiduguri',
  },
  {
    name: 'DHL Lokoja',
    city: 'Lokoja',
    address:
      'Suite 225, Salihu Ibrahim Road, Adjacent Federal Medical Center, Lokoja',
  },
  {
    name: 'DHL Kaduna',
    city: 'Kaduna',
    address: '16, Ahmadu Bello Way, Kaduna',
  },
  {
    name: 'DHL Zaria',
    city: 'Zaria',
    address:
      'Kola Bookshop House, Agoro, Tundun Wada, Zaria & ABU Bookshop, Samaru',
  },
  {
    name: 'DHL Minna',
    city: 'Minna',
    address: "IICO Building, 5 Muazu MOH'D Road, Minna",
  },
  {
    name: 'DHL Lafia',
    city: 'Lafia',
    address: 'No 1, Riza Plaza, Opp State CID H/Q, Lafia',
  },
  {
    name: 'DHL Makurdi',
    city: 'Makurdi',
    address: '24, Iyorchia Ayu, Inside NIIMA Hotel Premises, Wurukum, Makurdi',
  },
  {
    name: 'DHL Aba',
    city: 'Aba',
    address: 'No.8, Factory Road, Aba, Abia State',
  },
  {
    name: 'DHL Umuahia',
    city: 'Umuahia',
    address: '20, Ojike Street by Azikiwe Road, Umuahia',
  },
  { name: 'DHL Uyo', city: 'Uyo', address: '41 Oron Road, Uyo, Akwa Ibom' },
  {
    name: 'DHL Eket',
    city: 'Eket',
    address: '7 Grace Bille Road, Eket, Akwa Ibom',
  },
  {
    name: 'DHL Calabar',
    city: 'Calabar',
    address: '46 Ndidem Usang Iso Road/Mariam, Calabar, Cross Rivers State',
  },
  {
    name: 'DHL Bonny Island',
    city: 'Bonny Island',
    address: '5 Shell Road, Bonny, Rivers State',
  },
  {
    name: 'DHL Yenagoa',
    city: 'Yenagoa',
    address: 'Opposite NDDC Office, Kpansa, Yenagoa, Bayelsa State',
  },
  {
    name: 'DHL Warri',
    city: 'Warri',
    address: '56, Effurun/Sapele Road, Effurun, Delta State',
  },
  {
    name: 'DHL Sapele',
    city: 'Sapele',
    address: '57 Okpe Road, Sapele, Delta State',
  },
  {
    name: 'DHL Benin',
    city: 'Benin',
    address: '62 Airport Road, Akenzua Junction, Benin City, Edo State',
  },
  {
    name: 'DHL Asaba',
    city: 'Asaba',
    address: '305 Nnebisi Road, Asaba, Delta State',
  },
  {
    name: 'DHL Onitsha',
    city: 'Onitsha',
    address: '75 Old Market Road, Onitsha, Anambra State',
  },
  { name: 'DHL Nnewi', city: 'Nnewi', address: '104, Old Onitsha Road, Nnewi' },
  {
    name: 'DHL Nsukka',
    city: 'Nsukka',
    address: 'RM 104 GS Building, University of Nigeria, Nsukka',
  },
  {
    name: 'DHL Abakaliki',
    city: 'Abakaliki',
    address:
      '44 Ogoja Road Opposite Vegas, Beside Access Bank, Abakaliki, Ebonyi State',
  },
  {
    name: 'DHL Enugu',
    city: 'Enugu',
    address: "2A O'Conor Street, Asata, Enugu State",
  },
  {
    name: 'DHL Owerri',
    city: 'Owerri',
    address: '29 Weathral Road, Imo State',
  },
  {
    name: 'DHL Akure/Ado',
    city: 'Akure',
    address: '45, Oyemekun Road, Akure, Ondo State',
  },
  {
    name: 'DHL Ilorin',
    city: 'Ilorin',
    address: 'Taiwo Road, Ilorin, Kwara State',
  },
  {
    name: 'DHL Abeokuta',
    city: 'Abeokuta',
    address: '6 Lalubu Street, Abeokuta',
  },
  {
    name: 'DHL Ife/OSO',
    city: 'Ile Ife',
    address: 'Obafemi Awolowo University Campus, Ile Ife, Osun State',
  },
  {
    name: 'DHL Ota',
    city: 'Ota',
    address: 'ILO AWELA ROAD, TOLL GATE, Sango Otta, Ogun State',
  },
  {
    name: 'DHL Mowe',
    city: 'Mowe',
    address: 'Plot 44, Lagos Ibadan Express, Mowe',
  },
]

export default DHLOffices
