import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api'
import { useRef } from 'react'

function AutoComplete({
  onAddressSelect,
  tag,
  address,
  disabled,
}: {
  onAddressSelect: (data: any) => void
  tag: string
  address: any
  disabled: boolean
}) {
  const inputRef = useRef<any>(null)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCfUqqfA2zrU5rpDbBmzXf0WkB_6-nw_lY',
    libraries: ['places'],
  })

  // Function to extract address details from Google Places API response
  const extractAddressDetails = (place: any) => {
    if (!place || !place.address_components) return null

    let addressData: any = {
      address: '',
      city: '',
      state: '',
      country: '',
      postCode: '',
    }

    let sublocality = ''
    let locality = ''
    let stateLevel2 = ''

    place.address_components.forEach((component: any) => {
      const types = component.types

      if (types.includes('street_number')) {
        addressData.address = component.long_name
      }
      if (types.includes('route')) {
        addressData.address += addressData.address
          ? ` ${component.long_name}`
          : component.long_name
      }
      if (types.includes('sublocality_level_1')) {
        sublocality = component.long_name
      }
      if (types.includes('locality')) {
        locality = component.long_name
        addressData.city = component.long_name
      }
      if (types.includes('administrative_area_level_2')) {
        stateLevel2 = component.long_name
      }
      if (types.includes('administrative_area_level_1')) {
        addressData.state = component.long_name
      }
      if (types.includes('postal_town')) {
        addressData.postal_town = component.long_name // For UK cities
      }
      if (types.includes('country')) {
        addressData.country = component.short_name
      }
      if (types.includes('postal_code')) {
        addressData.postCode = component.long_name
      }
    })

    // **Adjust state for UK**
    if (addressData.country === 'GB' && stateLevel2) {
      addressData.state = stateLevel2
    }

    // **Format full address properly**
    let addressParts = [addressData.address]

    // Add sublocality if it's not the same as city
    if (sublocality && sublocality !== addressData.city) {
      addressParts.push(sublocality)
    }

    // Add locality if it's not the same as city
    if (locality && locality !== addressData.city) {
      addressParts.push(locality)
    }

    if (addressData.postal_town) {
      addressData.city = addressData.postal_town // Set level_2 as state for the UK
    }

    // Convert array to a string with ", " as separator
    addressData.address = addressParts.filter(Boolean).join(', ')
    if (addressData.address.length < 1 && place.formatted_address) {
      addressData.address = place.formatted_address.split(',')[0]
    }
    delete addressData.postal_town
    return addressData
  }

  // Function that gets triggered when an address is selected
  const handleOnPlacesChanged = () => {
    const places = inputRef.current?.getPlaces()
    if (places && places.length > 0) {
      const extractedDetails = extractAddressDetails(places[0])

      if (extractedDetails) {
        onAddressSelect(extractedDetails) // Return extracted details to parent component
      }
    }
  }

  return (
    <div>
      {isLoaded && (
        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handleOnPlacesChanged}
        >
          <div className='row'>
            <div className='address-card'>
              <p>{tag}</p>
              <input
                type='text'
                placeholder='Enter delivery address'
                defaultValue={address}
                disabled={disabled}
                autoComplete='new-password'
              />
            </div>
          </div>
        </StandaloneSearchBox>
      )}
    </div>
  )
}

export default AutoComplete
