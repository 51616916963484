// API function for making GET requests
const baseURL = (import.meta as any).env.VITE_BASE_URL
import axios from 'axios'

const generateToken = async () => {
  let token = sessionStorage.getItem('refreshToken')
  try {
    const res = await axios({
      method: 'GET',
      url: `${baseURL}/auth/generate-access`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    sessionStorage.setItem('token', res?.data?.data?.access_token)
    return res?.data?.data?.access_token // Indicate success
  } catch (error: any) {
    if (error.response) {
      // sessionStorage.clear();
      // window.location.href = "/login";
    }
    return false // Indicate failure
  }
}

export const get = async (endpoint: string) => {
  const token = await generateToken()
  try {
    const response = await fetch(`${baseURL}/${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await response.json()
    data.status = response.status // Adding status code to response data
    return data
  } catch (error) {
    throw error
  }
}

// API function for making POST requests with Auth
export const post = async (endpoint: string, body: any, token: any) => {
  let useToken: any = ''
  if (token) {
    useToken = token
  } else {
    useToken = await generateToken()
  }
  try {
    const response = await fetch(`${baseURL}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${useToken}`,
      },
      body: JSON.stringify(body),
    })
    const data = await response.json()
    data.status = response.status // Adding status code to response data
    return data
  } catch (error) {
    throw error
  }
}
// API function for making POST requests without authToken
export const postNo = async (endpoint: string, body: any) => {
  try {
    const response = await fetch(`${baseURL}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    const data = await response.json()
    data.status = response.status // Adding status code to response data
    return data
  } catch (error) {
    throw error
  }
}

// API function for making PUT requests with Auth
export const put = async (endpoint: string, body: any) => {
  const token = await generateToken()
  const requestOptions: { body?: any; method: string; headers: any } = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
  if (body !== null) {
    requestOptions.body = JSON.stringify(body)
  }
  try {
    const response = await fetch(`${baseURL}/${endpoint}`, requestOptions)
    const data = await response.json()
    data.status = response.status // Adding status code to response data
    return data
  } catch (error) {
    throw error
  }
}

// API function for making PUT requests without Auth
export const putNo = async (endpoint: string, body: any) => {
  try {
    const response = await fetch(`${baseURL}/${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    const data = await response.json()
    data.status = response.status // Adding status code to response data
    return data
  } catch (error) {
    throw error
  }
}

export const deleteAPI = async (endpoint: string) => {
  const token = await generateToken()
  try {
    const response = await fetch(`${baseURL}/${endpoint}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await response.json()
    data.status = response.status // Adding status code to response data
    return data
  } catch (error) {
    throw error
  }
}

export const upload = async (body: any) => {
  const token = await generateToken()
  const requestOptions: { body?: any; method: string; headers: any } = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: body,
  }
  try {
    const response = await fetch(`${baseURL}/upload`, requestOptions)
    const data = await response.json()
    data.status = response.status // Adding status code to response data
    return data
  } catch (error) {
    throw error
  }
}
