import { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import { useNavigate, useParams } from 'react-router-dom'
import { get } from '../../../../utils/Api'
import { useGlobalContext } from '../../../../utils/Context'
import PageLoader from '../../../../components/loader/PageLoader'
import CancelBooking from './CancelBooking'
import FormLoader from '../../../../components/loader/FormLoader'
import moment from 'moment'

function Booking() {
  const { id } = useParams()
  const { notify } = useGlobalContext()
  const [bookingData, setBookingData] = useState<any>({
    shipmentMeta: {
      trackingId: '',
      trackingUrl: '',
      packages: [
        {
          _id: '',
          referenceNumber: '',
          trackingNumber: '',
          trackingUrl: '',
          id: '',
        },
      ],
    },
    shipment_type: '',
    value: '',
    category: '',
    createdAt: '',
    number_items: 0,
    delivery_info: [
      {
        postalAddress: {
          postalCode: '',
          cityName: '',
          countryCode: '',
          addressLine1: '',
          countyName: '',
        },
        contactInformation: {
          phone: '',
          companyName: '',
          fullName: '',
          email: '',
        },
        _id: '',
        type: '',
        id: '',
      },
      {
        postalAddress: {
          postalCode: '',
          cityName: '',
          countryCode: '',
          addressLine1: '',
          countyName: '',
        },
        contactInformation: {
          phone: '',
          companyName: '',
          fullName: '',
          email: '',
        },
        _id: '',
        type: '',
        id: '',
      },
    ],
    packages: [
      {
        dimensions: {
          length: 0,
          width: 0,
          height: 0,
        },
        _id: '',
        weight: 0,
        id: '',
      },
    ],
  })
  const [showCancel, setShowCancel] = useState(false)
  const [SideBar, setSideBar] = useState<any>('false')
  const [informationPage, setInformationPage] = useState('delivery')
  const navigate = useNavigate()
  const [deliveryTab, setDeliveryTab] = useState('customer')
  const [fileName, setFileName] = useState('')
  const [bookingLoading, setBookingLoading] = useState(true)
  function downloadPDF(pdf: string) {
    const linkSource = `data:application/pdf;base64,${pdf}`
    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
  const fetchBooking = async () => {
    get(`booking/fetch-booking/${id}`)
      .then((result: any) => {
        if (result.status === 200) {
          setBookingData(result.data)
          setFileName(
            `${result.data.delivery_info[0].contactInformation.fullName}-${result.data.shipmentMeta.trackingId}.pdf`
          )
          fetchTracking(result.data.shipmentMeta.trackingId)
        } else {
          notify('error', result.er.message)
        }
        setBookingLoading(false)
      })
      .catch(() => {
        setBookingLoading(false)
      })
  }
  useEffect(() => {
    fetchBooking()
  }, [id])

  //<---------------Fetch Tracking Details---------------->
  const [trackingLoading, setTrackingLoading] = useState(true)
  const [trackingData, setTrackingData] = useState<any>([])

  const fetchTracking = async (trackingId: string) => {
    setTrackingLoading(true)
    get(`tracking/single?id=${trackingId}`)
      .then((result: any) => {
        if (result.status === 200) {
          setTrackingData(result.data.shipments[0])
        } else {
          notify('error', result.er.message)
        }
        setTrackingLoading(false)
      })
      .catch(() => {
        setTrackingLoading(false)
      })
  }
  return bookingLoading ? (
    <div className='LoaderPage'>
      <PageLoader position='center' />
    </div>
  ) : (
    <div className='booking-page'>
      {showCancel && (
        <CancelBooking
          fetchBooking={fetchBooking}
          setShowCancel={setShowCancel}
        />
      )}
      <div className='left'>
        <div className='top'>
          <div></div>
          <div className='burger'>
            <Icon
              icon='radix-icons:pin-left'
              onClick={() => setSideBar(!SideBar)}
            />
          </div>
        </div>
        <div className='back'>
          <div className='back_left'>
            <Icon
              icon='lucide:move-left'
              onClick={() => {
                navigate(-1)
              }}
            />
            <h1>Booking Info</h1>
          </div>
          <div className='back_right'>
            <p>Tracking ID: {bookingData.shipmentMeta.trackingId}</p>
          </div>
        </div>
        <div className='user_details'>
          <div className='user_right'>
            <div className='cardx'>
              <p>Shipment Type</p>
              <h2>{bookingData.shipment_type}</h2>
            </div>
            <div className='cardx'>
              <p>Delivery Type</p>
              <h2>{bookingData.deliveryType}</h2>
            </div>
            <div className='cardx'>
              <p>Charge</p>
              <h2>NGN {bookingData.charge.toLocaleString()}</h2>
            </div>
            <div className='cardx'>
              <p>Date/Time</p>
              <h2>{new Date(bookingData.createdAt).toLocaleString()}</h2>
            </div>
            <div className='cardx'>
              <p>Item No.</p>
              <h2>{bookingData.number_items}</h2>
            </div>
            <div className='cardx'>
              <p>Status</p>
              <span className={`chip ${bookingData.booking_status.code}`}>
                {bookingData.booking_status.description}
              </span>
            </div>

            <div className='cardz'>
              <p>Description</p>
              <h2>{bookingData.description}</h2>
            </div>
            {bookingData.booking_status.code === 'CCD' && (
              <div className='cardx'>
                <p className='color-red'>Cancellation Reason</p>
                <h2>{bookingData.reason}</h2>
              </div>
            )}

            {bookingData.booking_status.code === 'AWD' && (
              <div className='cardx'>
                <button className='cancel' onClick={() => setShowCancel(true)}>
                  Cancel Booking
                </button>
              </div>
            )}
          </div>
        </div>
        <div className='information_page'>
          {informationPage === 'delivery' && (
            <div className='info'>
              <div className='info_top'>
                <div className='info-top_wrap'>
                  <div className='one'>
                    <div className='icon'>
                      <Icon icon='tabler:truck-delivery' />
                    </div>
                    <h2>Delivery Information</h2>
                  </div>
                  <div className='two'>
                    <button
                      className={deliveryTab === 'customer' ? 'active' : ''}
                      onClick={() => setDeliveryTab('customer')}
                    >
                      Customer
                    </button>
                    <button
                      className={deliveryTab === 'reciever' ? 'active' : ''}
                      onClick={() => setDeliveryTab('reciever')}
                    >
                      Reciever
                    </button>
                  </div>
                </div>
              </div>
              {deliveryTab === 'customer' ? (
                <div className='info-wrap'>
                  <div className='mini-tag'>
                    <h2>Customer</h2>
                  </div>
                  <div className='single_wrap'>
                    <p>Address Line</p>
                    <h3>
                      {bookingData.delivery_info[0].postalAddress.addressLine1}
                    </h3>
                  </div>
                  <div className='four_wrap'>
                    <div className='cardx'>
                      <p>Postal Code</p>
                      <h3>
                        {bookingData.delivery_info[0].postalAddress.postalCode
                          .length > 1
                          ? bookingData.delivery_info[0].postalAddress
                              .postalCode
                          : 'null'}
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>City</p>
                      <h3>
                        {bookingData.delivery_info[0].postalAddress.cityName}
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>State/County</p>
                      <h3>
                        {bookingData.delivery_info[0].postalAddress.countyName}
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>Country Code</p>
                      <h3>
                        {bookingData.delivery_info[0].postalAddress.countryCode}
                      </h3>
                    </div>
                  </div>
                  <div className='mini-tag'>
                    <h2>Contact Info</h2>
                  </div>
                  <div className='four_wrap'>
                    <div className='cardx'>
                      <p>Full name</p>
                      <h3>
                        {
                          bookingData.delivery_info[0].contactInformation
                            .fullName
                        }
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>Phone</p>
                      <h3>
                        {bookingData.delivery_info[0].contactInformation.phone}
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>Email</p>
                      <h3>
                        {bookingData.delivery_info[0].contactInformation.email}
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>Company</p>
                      <h3>
                        {bookingData.delivery_info[0].contactInformation
                          .companyName.length > 1
                          ? bookingData.delivery_info[0].contactInformation
                              .companyName
                          : 'null'}
                      </h3>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='info-wrap'>
                  <div className='mini-tag'>
                    <h2>Reciever</h2>
                  </div>
                  <div className='single_wrap'>
                    <p>Address Line</p>
                    <h3>
                      {bookingData.delivery_info[1].postalAddress.addressLine1}
                    </h3>
                  </div>
                  <div className='four_wrap'>
                    <div className='cardx'>
                      <p>Postal Code</p>
                      <h3>
                        {bookingData.delivery_info[1].postalAddress.postalCode
                          .length > 1
                          ? bookingData.delivery_info[1].postalAddress
                              .postalCode
                          : 'null'}
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>City</p>
                      <h3>
                        {bookingData.delivery_info[1].postalAddress.cityName}
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>State/County</p>
                      <h3>
                        {bookingData.delivery_info[1].postalAddress.countyName}
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>Country Code</p>
                      <h3>
                        {bookingData.delivery_info[1].postalAddress.countryCode}
                      </h3>
                    </div>
                  </div>
                  <div className='mini-tag'>
                    <h2>Contact Info</h2>
                  </div>
                  <div className='four_wrap'>
                    <div className='cardx'>
                      <p>Full name</p>
                      <h3>
                        {
                          bookingData.delivery_info[1].contactInformation
                            .fullName
                        }
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>Phone</p>
                      <h3>
                        {bookingData.delivery_info[1].contactInformation.phone}
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>Email</p>
                      <h3>
                        {bookingData.delivery_info[1].contactInformation.email}
                      </h3>
                    </div>
                    <div className='cardx'>
                      <p>Company</p>
                      <h3>
                        {bookingData.delivery_info[1].contactInformation
                          .companyName.length > 1
                          ? bookingData.delivery_info[1].contactInformation
                              .companyName
                          : 'null'}
                      </h3>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {informationPage === 'package-tracking' && (
            <div className='info'>
              <div className='info_top'>
                <div className='info-top_wrap'>
                  <div className='one'>
                    <div className='icon'>
                      <Icon icon='grommet-icons:map-location' />
                    </div>
                    <h2>Package Tracking</h2>
                  </div>
                </div>
              </div>
              {trackingLoading ? (
                <FormLoader position='center' />
              ) : (
                <div className='info-table'>
                  {trackingData.events.length > 0 ? (
                    <>
                      {' '}
                      <table>
                        <thead>
                          <tr>
                            <th>S/N</th>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Location</th>
                          </tr>
                        </thead>
                        <tbody>
                          {trackingData.events.map((item: any, i: number) => (
                            <tr key={i + 1}>
                              <td>{1 + i}</td>
                              <td>
                                {moment(item.date).format('Do MMM, YYYY')} at{' '}
                                {moment(item.time, 'HH:mm:ss').format('h:mma')}
                              </td>
                              <td>{item.description}</td>
                              <td>{item.serviceArea[0].description}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className='mobile-table'>
                        {trackingData.events.map((item: any, i: number) => (
                          <div className='card'>
                            <div className='flex justify-between row'>
                              <div className='count flex items-center justify-center'>
                                {i + 1}
                              </div>
                              <p>
                                {' '}
                                {moment(item.date).format(
                                  'Do MMM, YYYY'
                                )} at{' '}
                                {moment(item.time, 'HH:mm:ss').format('h:mma')}
                              </p>
                            </div>
                            <div className='row flex justify-between flex-wrap'>
                              <h4>Description</h4>
                              <p>{item.description}</p>
                            </div>
                            <div className='row flex justify-between'>
                              <h4>Location</h4>
                              <p>{item.serviceArea[0].description}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className='empty flex justify-center'>
                      <div className='empty-cover'>
                        <Icon icon='material-symbols-light:track-changes-rounded' />
                        <h1>No Tracking Information To Show</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {informationPage === 'package-details' && (
            <div className='info'>
              <div className='info_top'>
                <div className='info-top_wrap'>
                  <div className='one'>
                    <div className='icon'>
                      <Icon icon='mage:package-box-fill' />
                    </div>
                    <h2>Package Details</h2>
                  </div>
                </div>
              </div>
              <div className='info-table'>
                <table>
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Weight</th>
                      <th>Length</th>
                      <th>Width</th>
                      <th>Height</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookingData.packages.map((item: any, i: number) => (
                      <tr key={item._id}>
                        <td>{1 + i}</td>
                        <td>{item.weight} kg</td>
                        <td>{item.dimensions.length}</td>
                        <td>{item.dimensions.width}</td>
                        <td>{item.dimensions.height}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className='mobile-table'>
                  {bookingData.packages.map((item: any, i: number) => (
                    <div className='card'>
                      <div className='flex justify-between row'>
                        <div className='count flex items-center justify-center'>
                          {i + 1}
                        </div>
                        <p>{item.weight} kg</p>
                      </div>
                      <div className='row flex justify-between flex-wrap'>
                        <h4>Length</h4>
                        <p>{item.dimensions.length}</p>
                      </div>
                      <div className='row flex justify-between'>
                        <h4>Width</h4>
                        <p>{item.dimensions.width}</p>
                      </div>
                      <div className='row flex justify-between'>
                        <h4>Height</h4>
                        <p>{item.dimensions.height}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={SideBar ? 'right' : 'right active'}>
        <div className='close'>
          <Icon
            icon='radix-icons:pin-left'
            onClick={() => setSideBar(!SideBar)}
          />
        </div>
        <div className='top'>
          {bookingData.booking_status.code !== 'CCD' && (
            <button
              onClick={() =>
                downloadPDF(bookingData.shipmentMeta.documents[0].content)
              }
            >
              Download Airway Bill
            </button>
          )}
        </div>
        <div className='tag'>
          <h1>More Information</h1>
        </div>
        <div className='links'>
          <ul>
            <li
              className={informationPage === 'delivery' ? 'active' : ''}
              onClick={() => {
                setInformationPage('delivery')
                setSideBar(!SideBar)
              }}
            >
              Delivery Information
            </li>
            <li
              className={informationPage === 'package-tracking' ? 'active' : ''}
              onClick={() => {
                setInformationPage('package-tracking')
                setSideBar(!SideBar)
              }}
            >
              Package Tracking
            </li>
            <li
              className={informationPage === 'package-details' ? 'active' : ''}
              onClick={() => {
                setInformationPage('package-details')
                setSideBar(!SideBar)
              }}
            >
              Package Details
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Booking
