import { useState } from 'react'
import { Link } from 'react-scroll'
import Logo from '../../../assets/images/logo.png'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'

function Navbar() {
  const navigate = useNavigate()
  const [showBar, setShowBar] = useState(false)
  return (
    <div className={`navbar flex ${showBar ? 'show' : ''}`}>
      <div className='flex nav-wrapper items-center justify-between '>
        <div className='logo'>
          <img src={Logo} alt='' />
          <Icon
            icon='bx:bx-menu'
            className='menu'
            onClick={() => setShowBar(!showBar)}
          />
        </div>
        <div className='rest flex justify-between items-center'>
          <ul className='flex'>
            {/* @ts-ignore */}
            <Link to='home' smooth={true} duration={1000}>
              <li>Home</li>
            </Link>
            {/* @ts-ignore */}
            <Link to='about-us' smooth={true} duration={1000}>
              <li>About Us</li>
            </Link>
            {/* @ts-ignore */}
            <Link to='why-us' smooth={true} duration={1000}>
              <li>Why Us</li>
            </Link>
            {/* @ts-ignore */}
            <Link to='reviews' smooth={true} duration={1000}>
              <li>Testimonials </li>
            </Link>
            {/* @ts-ignore */}
            <Link to='faqs' smooth={true} duration={1000}>
              <li>FAQs</li>
            </Link>
          </ul>
          <div className='buttons'>
            <button onClick={() => navigate('/users/login')}>Login</button>
            <button onClick={() => navigate('/create-account')}>Sign Up</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
