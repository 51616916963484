import { useState, useEffect } from 'react'
import { get } from '../../../../utils/Api'
import { useGlobalContext } from '../../../../utils/Context'
import { Icon } from '@iconify/react'
import FormLoader from '../../../../components/loader/FormLoader'
import Pagination from '../../../../components/pagination/Pagination'

function Transactions() {
  const { notify } = useGlobalContext()
  const [transactions, setTransactions] = useState<any>({ transactions: [] })
  const [transactionsLoading, setTransactionsLoading] = useState(true)
  const [paginationPage, setPaginationPage] = useState('default')

  function fetchTransactions(page: number) {
    setTransactionsLoading(true)
    get(`user/transactions?page=${page}&limit=10`)
      .then((result: any) => {
        if (result.status === 200) {
          setTransactions(result)
        } else {
          notify('error', result.message)
        }
        setTransactionsLoading(false)
      })
      .catch(() => {
        setTransactionsLoading(false)
      })
  }
  const fetchFilteredTransactions = async (page: number) => {
    setTransactionsLoading(true)
    get(
      `user/transactions?from=${dates.from}&to=${dates.to}&page=${page}&limit=10`
    )
      .then((result: any) => {
        if (result.status === 200) {
          setTransactions(result)
        } else {
          notify('error', result.message)
        }
        setTransactionsLoading(false)
      })
      .catch(() => {
        setTransactionsLoading(false)
      })
  }
  useEffect(() => {
    fetchTransactions(1)
  }, [])

  //Filter
  const [dates, setDates] = useState({
    from: '',
    to: '',
  })
  const handleChange = (e: any) => {
    const { name, value } = e.target
    setDates((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  function handleFilter() {
    if (!dates.from || !dates.to) {
      notify('error', `Dates can't be empty`)
    } else {
      setPaginationPage('date')
      fetchFilteredTransactions(1)
    }
  }

  return (
    <div className='home'>
      <div className='home_tag flex justify-between items-center'>
        <h1></h1>
        <div className='flex items-center select-wrapper'>
          <div className='flex items-center'>
            <p></p>
            <input
              type='date'
              name='from'
              value={dates.from}
              onChange={handleChange}
              max={new Date().toISOString().split('T')[0]}
            />
          </div>
          <div className='flex items-center'>
            <p>to</p>
            <input
              type='date'
              name='to'
              value={dates.to}
              onChange={handleChange}
              min={dates.from}
              max={new Date().toISOString().split('T')[0]}
            />
          </div>

          <button disabled={!dates.from || !dates.to} onClick={handleFilter}>
            filter
          </button>
        </div>
      </div>
      <div className='home_tables'>
        <div className='left'>
          {transactionsLoading ? (
            <FormLoader position='center' />
          ) : (
            <>
              {transactions.transactions.length > 0 ? (
                <>
                  <div className='mobile-table'>
                    {transactions.transactions.map((item: any, i: number) => (
                      <div className='card'>
                        <div className='flex justify-between row'>
                          <div className='count flex items-center justify-center'>
                            {i + 1}
                          </div>
                          <p> {new Date(item.created_at).toLocaleString()}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Amount</h4>
                          <p>₦{item.amount.toLocaleString()}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Type</h4>
                          <p className={item.type}>{item.type}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Desc</h4>
                          <p>{item.usage}</p>
                        </div>
                        <div className='row flex justify-between'>
                          <h4>Reference</h4>
                          <p>
                            {item.reference.length > 17
                              ? `${item.reference.slice(0, 17)}...`
                              : item.reference}
                          </p>
                        </div>

                        <div className='row flex justify-between'>
                          <h4>Status</h4>
                          <p className={item.status}>{item.status}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <table className='desktop-table'>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Date/Time</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Use</th>
                        <th>Reference</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.transactions.map((item: any, i: number) => (
                        <tr key={item._id}>
                          <td>{i + 1}</td>
                          <td className='count'>
                            {new Date(item.created_at).toLocaleString()}
                          </td>
                          <td className='count'>
                            NGN {item.amount.toLocaleString()}
                          </td>
                          <td className={item.type}>{item.type}</td>
                          <td>{item.usage}</td>
                          <td>{item.reference}</td>
                          <td className={item.status}>{item.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={transactions.currentPage}
                    totalCount={transactions.totalPages}
                    pageSize={transactions.limit}
                    onPageChange={(page: number) => {
                      paginationPage === 'default'
                        ? fetchTransactions(page)
                        : fetchFilteredTransactions(page)
                    }}
                  />
                </>
              ) : (
                <div className='empty flex justify-center'>
                  <div className='empty-cover'>
                    <Icon icon='la:clipboard-list' />
                    <h1>No Transaction To Show</h1>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Transactions
